import { createContext } from "react";

export interface ITenantInfo {
  companyName: string;
  domain: string;
}

interface ITenantContext {
  tenantInfo?: ITenantInfo;
  setTenantInfo: React.Dispatch<React.SetStateAction<ITenantInfo | undefined>>;
}

const defaultValue: ITenantContext = {
  tenantInfo: undefined,
  setTenantInfo: () => {
    throw new Error("You must wrap your component in a TenantProvider");
  },
};

export default createContext(defaultValue);
