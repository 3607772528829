import { TriangleDownIcon, TriangleUpIcon } from "@chakra-ui/icons";
import { chakra, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { ReactNode } from "react";
import { Column, useSortBy, useTable } from "react-table";

export interface ISortableTableProps<T extends object> {
  /**
   * @description the data and column definitions for the table
   */
  tableProps: {
    /**
     * @description the data to display as table rows
     */
    data: readonly T[];
    /**
     * @description the columns to show in the table
     */
    columns: readonly Column<T>[];
  };
  /**
   * @description an optional function to execute when a table row is clicked
   */
  onClick?: (rowVal: T) => void;
}

/**
 * @description a styled sortable table
 */
export default function SortableTable<T extends object>({
  tableProps,
  onClick,
}: ISortableTableProps<T>) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable<T>(tableProps, useSortBy);
  return (
    <Table {...getTableProps()}>
      <Thead>
        {headerGroups.map((headerGroup) => (
          <Tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <Th {...column.getHeaderProps(column.getSortByToggleProps())}>
                {column.render("Header")}
                <chakra.span pl={4}>
                  {column.isSorted ? (
                    column.isSortedDesc ? (
                      <TriangleDownIcon aria-label="sorted descending" />
                    ) : (
                      <TriangleUpIcon aria-label="sorted ascending" />
                    )
                  ) : null}
                </chakra.span>
              </Th>
            ))}
          </Tr>
        ))}
      </Thead>
      <Tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          const rowProps = row.getRowProps();
          let RowComponent = ({ children }: { children: ReactNode }) => (
            <Tr {...rowProps}>{children}</Tr>
          );
          if (onClick !== undefined) {
            RowComponent = ({ children }: { children: ReactNode }) => (
              <Tr
                {...rowProps}
                _hover={{ cursor: "pointer", background: "gray.200" }}
                onClick={() => onClick(row.original)}
                key={row.id}
              >
                {children}
              </Tr>
            );
          }
          return (
            <RowComponent key={row.id}>
              {row.cells.map((cell, idx) => (
                <Td {...cell.getCellProps()} key={idx}>
                  {cell.render("Cell")}
                </Td>
              ))}
            </RowComponent>
          );
        })}
      </Tbody>
    </Table>
  );
}
