import { ExternalLinkIcon, HamburgerIcon } from "@chakra-ui/icons";
import {
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { useContext } from "react";
import authContext from "../../../contexts/auth.context";
import { getAdminConsentLink } from "../../../utils/utils";

interface ITenantLinkMenuProps {
  tenantId: string;
}

export default function TenantLinkMenu({ tenantId }: ITenantLinkMenuProps) {
  const { isGov } = useContext(authContext);

  const openLink = (link: string, target = "_blank") => {
    window.open(link, target);
  };

  return (
    <Menu>
      <MenuButton
        as={IconButton}
        aria-label="tenant-link-menu"
        icon={<HamburgerIcon />}
        variant="ghost"
        size="sm"
        marginLeft={2}
      />
      <MenuList>
        <MenuItem
          icon={<ExternalLinkIcon />}
          onClick={() => {
            const consentLink = getAdminConsentLink(tenantId, isGov);
            openLink(consentLink, "_self");
          }}
        >
          Admin consent page
        </MenuItem>
      </MenuList>
    </Menu>
  );
}
