import { H3 } from "../../../../../Components/Headings/Headings";
import { TextSection } from "../../../../../Components/Text/Text";

export default function IncidentResponsePolicy() {
  return (
    <>
      <H3 id="incident-response-policy">Incident Response Policy</H3>
      <TextSection>
        All incidents must be reported to company management within 48 hours of
        an incident being discovered. Employees are required to report suspected
        security incidents to the company's management within this time frame.
        In order to be effective this response will include company's
        coordination with Atomus, external cybersecurity firms focusing on
        incident response, customers who may have been impacted, and appropriate
        representatives within the Department of Defense supply chain risk
        management office. A test of the company's incident response
        capabilities which will include the use of checklists and a walkthrough
        of the steps needed to respond to an incident occurs annually.
      </TextSection>
      <TextSection>
        For incidents involving CUI, company management will contact Atomus and
        begin the process of submitting a report detailing the incident. This
        report will include audit monitoring, network monitoring, physical
        access monitoring, user/administrator reports, and reported supply chain
        events. The incident handling capability will require Atomus and the
        company to prepare and detect for incidents leveraging industry standard
        precautions. As part of the report the company puts together with the
        assistance of Atomus detailing the incident. The status of this report
        and information contained inside this report will be tracked and
        reported to the company's management. If deemed necessary by company
        management, the company will then contract with an incident response
        firm in order to manage the analysis, containment, eradication, and
        recovery of any data as part of an incident.
      </TextSection>
    </>
  );
}
