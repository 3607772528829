import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { SetStateAction, useState } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { MsalAuthConfigs } from "../constants/auth.constants";
import { NO_REFETCH_QUERY } from "../constants/react-query.constants";
import AuthContext from "../contexts/auth.context";
import { IReactChildren } from "../types/props.types";

const QUERY_CLIENT = new QueryClient({
  defaultOptions: { queries: { ...NO_REFETCH_QUERY, retry: false } },
});

const GOV_INSTANCE = new PublicClientApplication(MsalAuthConfigs.gov);
const COMMERCIAL_INSTANCE = new PublicClientApplication(
  MsalAuthConfigs.commercial
);

export default function AuthProvider({ children }: IReactChildren) {
  const [instance, setInstance] = useState(COMMERCIAL_INSTANCE);
  const [isGov, _setIsGov] = useState(false);

  const setIsGov = (target: SetStateAction<boolean>) => {
    if (target !== isGov) {
      // clear react-query to avoid leaking any data between accounts
      QUERY_CLIENT.clear();
      QUERY_CLIENT.removeQueries();
      sessionStorage.removeItem("current-internals-token");
      _setIsGov(target);
      setInstance(target ? GOV_INSTANCE : COMMERCIAL_INSTANCE);
    }
  };

  return (
    <AuthContext.Provider value={{ isGov, setIsGov }}>
      <QueryClientProvider client={QUERY_CLIENT}>
        <MsalProvider instance={instance}>{children}</MsalProvider>
      </QueryClientProvider>
    </AuthContext.Provider>
  );
}
