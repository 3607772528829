import { H1 } from "../Headings/Headings";
import PageContainer from "../PageContainer/PageContainer";
import { useCompanyInfoQuery } from "../../hooks/queries.hooks";
import { ReactNode, useEffect } from "react";
import { useParams } from "react-router-dom";

interface IDocumentationPageProps {
  pageName: string;
  sectionId: string;
  children?: ReactNode;
}

export default function DocumentationPage({
  pageName,
  children,
  sectionId,
}: IDocumentationPageProps) {
  const tenantId = useParams().tenantId as string;
  const { data } = useCompanyInfoQuery(tenantId);
  useEffect(() => {
    if (data?.companyName) {
      document.title = `[${sectionId}] ${pageName} - ${data.companyName} v3.0`;
    } else {
      document.title = `[${sectionId}] ${pageName} v3.0`;
    }
  }, [data, pageName, sectionId]);

  return (
    <PageContainer>
      <H1>{pageName}</H1>
      {children}
    </PageContainer>
  );
}
