import { H3 } from "../../../../../Components/Headings/Headings";
import { TextSection } from "../../../../../Components/Text/Text";

export default function CompanyVisitorPolicy() {
  return (
    <>
      <H3 id="company-visitor-policy">Company Visitor Policy</H3>
      <TextSection>
        Any visitors to the company must be escorted by a company employee
        during their entire visit to the company's facilities. This includes the
        maintenance activities of maintenance personnel without required access
        authorization. All visitors must check in with company management who
        maintains visitor access records for the day.
      </TextSection>
    </>
  );
}
