import { H2, H3 } from "../../../Components/Headings/Headings";
import { TextSection } from "../../../Components/Text/Text";
import WindowsRemoteDesktopScreenshot from "../../../assets/screenshots/WindowsRemoteDesktopScreenshot.png";
import SectionImage from "../../../Components/SectionImage/SectionImage";
import { Box, Link } from "@chakra-ui/react";

export default function RemoteAccessSection() {
  return (
    <>
      <H2 id="remoteaccess">Remote Access</H2>
      <TextSection>
        Typically, Aegis machines reside on the actual workstations. So, they do
        not allow for remote sessioins or connections.
      </TextSection>
      <TextSection>
        By default, the Remote Desktop setting is switched off:
      </TextSection>
      <SectionImage src={WindowsRemoteDesktopScreenshot} />
      <Box marginY={4} />
      <H3>Permitted Remote Access</H3>
      <TextSection>
        Only <strong>Security Admin</strong> can trigger patches, updates and
        support through Azure Arc and Intune.
      </TextSection>
      <Box marginY={4} />
      <H3>Security</H3>
      <Link
        href="https://www.remotepc.com/helpdesk/faq-security.htm"
        target="_blank"
      >
        RemotePC HelpDesk Security FAQs
      </Link>
    </>
  );
}
