import { Box, Divider, Flex } from "@chakra-ui/react";
import { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import trainingSlidesPdf from "../../../assets/pdfs/trainingSlides.pdf";
import { TextSection } from "../../../Components/Text/Text";
import SectionLink from "../../../Components/Links/SectionLink";
import "pdfjs-dist/build/pdf.worker.entry";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.js",
  import.meta.url
).toString();

export default function TrainingSlidesSection() {
  const [totalPages, setTotalPages] = useState<number>();

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setTotalPages(numPages);
  };

  return (
    <>
      <TextSection>
        Each user reviews these slides as part of their cybersecurity and CUI
        training (in addition to the trainings described in{" "}
        <SectionLink sectionId="trainingevidence" />
        ).
      </TextSection>
      <Flex flexDirection="column" alignItems="center" marginTop={5}>
        <Box margin={2}>
          <Document
            file={trainingSlidesPdf}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            {Array.from(new Array(totalPages), (_, index) => (
              <Box key={`page_${index + 1}`}>
                <Page pageNumber={index + 1} renderAnnotationLayer={false} />
                {index !== (totalPages ?? 1) - 1 && (
                  <Divider
                    my={4}
                    borderColor="gray.500"
                    borderWidth="1px"
                    width="100%"
                  />
                )}
              </Box>
            ))}
          </Document>
        </Box>
      </Flex>
    </>
  );
}
