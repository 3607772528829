import { H3 } from "../../../../../Components/Headings/Headings";
import { TextSection } from "../../../../../Components/Text/Text";

export default function ByteDanceAppPolicy() {
  return (
    <>
      <H3 id="bytedance-application-policy">ByteDance Application Policy</H3>
      <TextSection>
        As per regulation{" "}
        <a href="https://www.acquisition.gov/far/52.204-27" target="_blank">
          FAR 52.204-27
        </a>
        , ByteDance applications are not allowed to be installed on any device
        that will have access to organizational data. This includes applications
        such as TikTok, Douyin, and any other ByteDance-owned application.
      </TextSection>
      <TextSection>
        The purpose of this policy is to ensure the security and confidentiality
        of our company's sensitive data and to comply with federal regulations.
        ByteDance applications have been found to collect and transmit large
        amounts of user data, and their use on devices with access to
        organizational data poses a significant risk to our company's
        cybersecurity.
      </TextSection>
      <TextSection>
        If you have any questions or concerns about this policy, please contact
        the IT department for further guidance. We appreciate your cooperation
        in maintaining the security of our company's data.
      </TextSection>
    </>
  );
}
