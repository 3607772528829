import { H3 } from "../../../../../Components/Headings/Headings";
import { TextSection } from "../../../../../Components/Text/Text";

export default function RiskManagementPolicy() {
  return (
    <>
      <H3 id="risk-management-policy">Risk Management Policy</H3>
      <TextSection>
        The company conducts periodic risk assessments for company operations
        including mission, functions, image, and reputation, company assets, and
        individuals, resulting from the operation of company information
        systems, and the associated processing, storage, and transmission of
        CUI. Risk assessments are viewed as living documents and incorporated
        into the company's long term planning.
      </TextSection>
      <TextSection>
        In order to supplement this risk management policy, the company has
        periodic security assessments done by Atomus to ensure that security
        controls are implemented correctly to meet the needed security
        requirements. This assessment scopes all CUI information systems and
        networks, including all security requirements and procedures necessary
        to meet the compliance requirements of the environment. In addition, the
        assessment includes, but is not limited to, vulnerability scanning,
        penetration testing, security control testing and reviews, configuration
        testing and reviews, log reviews, and talking with company employees.
        This assessment is conducted by company management and Atomus in order
        to ensure compliance with cybersecurity industry standards. The written
        final assessment is provided to company management.
      </TextSection>
      <TextSection>
        System security plans are kept up to date in order to identify system
        boundaries, system environments, how security requirements are
        implemented, and the relationships with or connections to other systems
        in conjunction with Atomus. As cybersecurity is an evolving threat,
        system flaws are identified, reported and corrected within time periods
        dictated by management. As part of this process these flaws are
        categorized into 3 different categories when it comes to remediation
        each with different amounts of time to remediate issues. The categories
        are as follows: Green (1 month), Yellow (3 months), and Red (6 months).
        These response times are benchmarked with industry standards.
      </TextSection>
    </>
  );
}
