import { Table, Thead, Tr, Th, Tbody, Td, Text } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { H3 } from "../../../../Components/Headings/Headings";
import LoadingBar from "../../../../Components/LoadingBar/LoadingBar";
import { useAllRegKeysQuery } from "../../../../hooks/queries.hooks";

export default function RegKeyTable() {
  const tenantId = useParams().tenantId as string;
  const regKeyQuery = useAllRegKeysQuery(tenantId);

  return (
    <>
      <H3>List of Managed Registry Keys</H3>
      {regKeyQuery.isLoading && <LoadingBar />}
      {regKeyQuery.isError && (
        <Text color="red">
          Error getting tenant reg keys: {regKeyQuery.error.message}
        </Text>
      )}
      {regKeyQuery.data && (
        <Table variant="striped">
          <Thead>
            <Tr>
              <Th>Operation</Th>
              <Th>Key</Th>
              <Th>Type</Th>
              <Th>Value</Th>
            </Tr>
          </Thead>
          <Tbody>
            {Object.entries(regKeyQuery.data).map(([fullPath, opSpec]) => (
              <Tr key={fullPath}>
                <Td>{opSpec.Operation}</Td>
                <Td>{fullPath}</Td>
                <Td>{opSpec.Operation === "set" ? opSpec.Type : ""}</Td>
                <Td>{opSpec.Operation === "set" ? opSpec.Value : ""}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      )}
    </>
  );
}
