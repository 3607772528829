import { Box } from "@chakra-ui/react";
import { IReactChildren } from "../../types/props.types";

export default function BoxContainer({ children }: IReactChildren) {
  return (
    <Box
      padding={2}
      marginY={2}
      border="1px solid"
      borderColor="gray.300"
      borderRadius="md"
    >
      {children}
    </Box>
  );
}
