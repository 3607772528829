import { AspectRatio, Box, Card, Flex, Text, Image } from "@chakra-ui/react";
import { H2, H3 } from "../../../Components/Headings/Headings";
import { ReactComponent as AtomusLogo } from "../../../assets/logomark.svg";
import { TextSection } from "../../../Components/Text/Text";
import AzureServices from "../../../assets/AzureServices.png";

export default function AtomusAegisPlatformSection() {
  const handleLinkClick = () => {
    window.open("https://atomuscyber.com");
  };
  return (
    <>
      <H2 id="atomusaegis">Atomus Aegis Platform</H2>
      <Card
        variant="outline"
        padding={2}
        onClick={handleLinkClick}
        _hover={{ cursor: "pointer", background: "blackAlpha.200" }}
        marginTop={2}
        marginBottom={4}
      >
        <Flex alignItems="center">
          <Box objectFit="cover" maxW={{ base: "100%", sm: "200px" }}>
            <AtomusLogo />
          </Box>
          <Box marginX={2}>
            <H3>
              Atomus: All-in-one cybersecurity and compliance regulations
              solution
            </H3>
            <Text>https://atomuscyber.com</Text>
          </Box>
        </Flex>
      </Card>
      <H3>Atomus Aegis Demo Video</H3>
      <AspectRatio marginY={4} maxWidth="700px">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/tp8B_N2LDIk"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        />
      </AspectRatio>
      <TextSection>
        The Aegis Platform uses a <strong>Standardized Desktop Agent</strong> to
        enforce, monitor and track all security policies, configurations, logs,
        patches, updates, and control of the machines based on the organizaton.
        The list of services that we run on the{" "}
        <strong>Azure Commercial Cloud</strong> are listed as below:
        <Image src={AzureServices} />
      </TextSection>
    </>
  );
}
