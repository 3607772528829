import { Box, Divider, Grid, Text } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { H3 } from "../../../../Components/Headings/Headings";
import LoadingBar from "../../../../Components/LoadingBar/LoadingBar";
import { TextSection } from "../../../../Components/Text/Text";
import { useLatestHealthchecks } from "../../../../hooks/queries.hooks";
import { HealthcheckRow } from "../../../../types/atomus-internal-apis.types";
import { useEffect, useState } from "react";

export default function DeviceHealthcheckEvidence() {
  const [groupedHcs, setGroupedHcs] = useState<HealthcheckRow[][]>([]);
  const tenantId = useParams().tenantId as string;
  const hcQuery = useLatestHealthchecks(tenantId);

  useEffect(() => {
    if (hcQuery.data) {
      let group: HealthcheckRow[] = [];
      const newGroupedHcs: HealthcheckRow[][] = [];
      hcQuery.data.forEach((hcTest, idx) => {
        group.push(hcTest);
        if (
          idx === hcQuery.data.length - 1 ||
          hcQuery.data[idx + 1].id_g !== hcTest.id_g
        ) {
          newGroupedHcs.push(group);
          group = [];
        }
      });
      setGroupedHcs(newGroupedHcs);
    }
  }, [hcQuery.data]);

  return (
    <>
      <H3>Aegis Device Healthcheck Evidence</H3>
      {hcQuery.isLoading && <LoadingBar />}
      {hcQuery.isError && (
        <Text color="red">
          Error getting device healthcheck data: {hcQuery.error.message}
        </Text>
      )}
      {groupedHcs.length > 0 &&
        groupedHcs.map((hcGroup) => (
          <Box
            key={hcGroup[0]?.deviceName_s}
            padding={2}
            margin={2}
            border="1px solid"
            borderColor="gray.300"
            borderRadius="md"
          >
            <TextSection>
              <strong>{hcGroup[0]?.deviceName_s}</strong>
            </TextSection>
            {hcGroup.map((hcTest) => (
              <>
                <Divider marginY={2} />
                {Object.entries(hcTest).map(([key, value]) => (
                  <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                    <Text fontWeight="semibold">{key}:</Text>
                    <Text>{value}</Text>
                  </Grid>
                ))}
              </>
            ))}
          </Box>
        ))}
    </>
  );
}
