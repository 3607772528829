import { Center, Link, Text } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { H2, H3 } from "../../../Components/Headings/Headings";
import LoadingBar from "../../../Components/LoadingBar/LoadingBar";
import PageContainer from "../../../Components/PageContainer/PageContainer";
import { TextSection } from "../../../Components/Text/Text";
import { useCompanyInfoQuery } from "../../../hooks/queries.hooks";

export default function PrivilegedUsersContactSection() {
  const tenantId = useParams().tenantId as string;
  const { data, isLoading, isError, error } = useCompanyInfoQuery(tenantId);
  if (isLoading) {
    return (
      <PageContainer>
        <LoadingBar />
      </PageContainer>
    );
  }

  if (isError) {
    return (
      <Center>
        <Text color="red">Error fetching company info: {error.message}</Text>
      </Center>
    );
  }

  if (!data) {
    return (
      <Center>
        <Text color="red">
          Could not get data even though query is not loading and there is no
          error.
        </Text>
      </Center>
    );
  }

  return (
    <>
      <H2 id="privilegedusers">
        Privileged Users - Contact and Reporting Information
      </H2>
      {(data.email || data.phone || data.companyContactUsWebsite) && (
        <>
          <H3>Company Admin</H3>
          {data.name && (
            <TextSection>
              <strong>Name:</strong> {data.name}
            </TextSection>
          )}
          {data.email && (
            <TextSection>
              <strong>Email:</strong> {data.email}
            </TextSection>
          )}
          {data.phone && (
            <TextSection>
              <strong>Phone:</strong> {data.phone}
            </TextSection>
          )}
          {data.companyContactUsWebsite && (
            <TextSection>
              <strong>Website: </strong>
              <Link href={data.companyContactUsWebsite} isExternal>
                {data.companyContactUsWebsite}
              </Link>
            </TextSection>
          )}
        </>
      )}
      <>
        <H3>Atomus Security Admin</H3>
        <TextSection>
          <strong>Email:</strong> success@atomuscyber.com
        </TextSection>
        <TextSection>
          <strong>Phone:</strong> (415) 941-6745
        </TextSection>
      </>
    </>
  );
}
