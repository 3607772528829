import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "./Components/AuthTemplates/AuthTemplates";
import CompanyInfoPage from "./Pages/CompanyInfoPage/CompanyInfoPage";
import LoginPage from "./Pages/LoginPage/LoginPage";
import TenantHomePage from "./Pages/TenantHomePage/TenantHomePage";
import TenantsListPage from "./Pages/TenantsListPage/TenantsListPage";
import "./App.css";
import { CATEGORIES } from "./categories";
import DocumentationPage from "./Components/DocumentationPage/DocumentationPage";

function App() {
  return (
    <>
      <BrowserRouter>
        <AuthenticatedTemplate>
          <Routes>
            {/* These paths are required (with emtpy elemet) for the msal auth process */}
            <Route path="/tenants" element={<TenantsListPage />} />
            <Route path="/tenants/:tenantId" element={<TenantHomePage />} />

            <Route
              path="/tenants/:tenantId/companyinfo"
              element={<CompanyInfoPage />}
            />

            {CATEGORIES.map((parent) =>
              parent.sections.map((section) => (
                <Route
                  key={section.sectionId}
                  path={`/tenants/:tenantId/${parent.categoryId}/${section.sectionId}`}
                  element={
                    <DocumentationPage
                      pageName={section.sectionName}
                      sectionId={section.sectionId}
                    >
                      <section.element />
                    </DocumentationPage>
                  }
                />
              ))
            )}

            <Route path="/*" element={<Navigate to="/tenants" />} />
          </Routes>
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <Routes>
            <Route path="/login" element={<LoginPage />} />
            <Route path="*" element={<LoginPage />} />
          </Routes>
        </UnauthenticatedTemplate>
      </BrowserRouter>
    </>
  );
}

export default App;
