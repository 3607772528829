import { ChakraProvider } from "@chakra-ui/react";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { MSAL_REDIRECT_PATH } from "./constants/auth.constants";
import AuthProvider from "./Providers/AuthProvider";
import TenantProvider from "./Providers/TenantProvider";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
if (window.location.pathname === MSAL_REDIRECT_PATH) {
  const searchParams = new URLSearchParams(document.location.search);
  let redirectUrl = `${window.location.protocol}//${window.location.hostname}`;
  if (window.location.hostname === "localhost") {
    redirectUrl = `${redirectUrl}:${window.location.port}`;
  }
  const state = searchParams.get("state");
  if (state) {
    redirectUrl = `${redirectUrl}${state}`;
  } else {
    redirectUrl = `${redirectUrl}/tenants`;
  }
  setTimeout(() => {
    window.open(redirectUrl, "_self");
  }, 1000);
} else {
  root.render(
    <React.StrictMode>
      <ChakraProvider>
        <AuthProvider>
          <TenantProvider>
            <App />
          </TenantProvider>
        </AuthProvider>
      </ChakraProvider>
    </React.StrictMode>
  );
}
