import { H3 } from "../../../../../Components/Headings/Headings";
import { TextSection } from "../../../../../Components/Text/Text";

export default function CompanyMediaSanitizationPolicy() {
  return (
    <>
      <H3 id="company-media-sanitization-policy">
        Company Media Sanitization Policy
      </H3>
      <TextSection>
        In order to keep CUI secure, any equipment removed offsite needs to be
        sanitized of CUI. This process requires an employee to go through the
        system and manually verify there is no CUI or company information stored
        on the equipment. In the case information is identified, the employee
        shall promptly delete, encrypt, or destroy the information to ensure
        that no usable data is retrievable by an unauthorized part in accordance
        with industry standard procedures. This policy includes any media that
        is removed from the premises for maintenance, repair or disposal. This
        policy also requires the sanitization of both digital and non-digital
        media containing CUI before reuse or disposal. In the case that company
        management identifies the need to transport CUI data outside the
        ' facilities, employees will ensure that all CUI data on media
        is encrypted or physically locked prior to transport outside of the
        ' physically protected areas.
      </TextSection>
    </>
  );
}
