import ByteDanceAppPolicy from "./Policies/ByteDanceAppPolicy";
import CompanyCuiAccessPolicy from "./Policies/CompanyCuiAccessPolicy";
import CompanyMediaSanitizationPolicy from "./Policies/CompanyMediaSanitizationPolicy";
import CompanyVisitorPolicy from "./Policies/CompanyVisitorPolicy";
import ForeignTravelPolicy from "./Policies/ForeignTravelPolicy";
import IncidentResponsePolicy from "./Policies/IncidentResponsePolicy";
import MobileAndPersonalDevicePolicy from "./Policies/MobileAndPersonalDevicePolicy";
import PhysicalSecurityPolicies from "./Policies/PhysicalSecurityPolicies";
import PublicInformationPolicy from "./Policies/PublicInformationPolicy";
import RiskManagementPolicy from "./Policies/RiskManagementPolicy";
import UseOfCompanyInformationSystemsPolicy from "./Policies/UseOfCompanyInformationSystemsPolicy";

export default function PolicyDefinitions() {
  return (
    <>
      <UseOfCompanyInformationSystemsPolicy />
      <CompanyCuiAccessPolicy />
      <RiskManagementPolicy />
      <PublicInformationPolicy />
      <IncidentResponsePolicy />
      <CompanyMediaSanitizationPolicy />
      <CompanyVisitorPolicy />
      <PhysicalSecurityPolicies />
      <MobileAndPersonalDevicePolicy />
      <ByteDanceAppPolicy />
      <ForeignTravelPolicy />
    </>
  );
}
