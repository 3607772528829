import { Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { H2, H3 } from "../../../Components/Headings/Headings";
import LinkCard from "../../../Components/Links/LinkCard";
import SectionLink from "../../../Components/Links/SectionLink";
import LoadingBar from "../../../Components/LoadingBar/LoadingBar";
import { TextSection } from "../../../Components/Text/Text";
import { useTenantsQuery } from "../../../hooks/queries.hooks";
import { TenantData } from "../../../types/atomus-internal-apis.types";

const Links = {
  RBAC_OVERVIEW:
    "https://learn.microsoft.com/en-us/azure/active-directory/roles/custom-overview",
  AAD_DATA_SECURITY_CONSIDERATIONS:
    "https://azure.microsoft.com/en-us/resources/azure-active-directory-data-security-considerations/",
} as const;

export default function IdentityDomainEvidenceSection() {
  const tenantId = useParams().tenantId as string;
  const { data, isLoading, isError, error } = useTenantsQuery();
  const [tenant, setTenant] = useState<TenantData | null>(null);

  useEffect(() => {
    if (data) {
      setTenant(data.success.find((t) => t.tenantId === tenantId) || null);
    }
  }, [data, tenantId]);
  return (
    <>
      <H2 id="identitydomainevidence">Identity Domain Evidence</H2>
      {isLoading && <LoadingBar />}
      {isError && (
        <Text color="red">Error getting tenants: {error.message}</Text>
      )}
      {tenant && (
        <>
          <TextSection>
            <strong>Domain:</strong> {tenant.domain}
          </TextSection>
          <SectionLink sectionId="companyinfo" />
        </>
      )}
      <H3>Role Based Access Control (RBAC) in Azure Active Directory</H3>
      <LinkCard
        title="Overview of role-based access control in Azure Active Directory"
        link={Links.RBAC_OVERVIEW}
      />
      <LinkCard
        title="Azure Active Directory Data Security Considerations"
        link={Links.AAD_DATA_SECURITY_CONSIDERATIONS}
      />
    </>
  );
}
