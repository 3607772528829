import {
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Text,
  Badge,
  Skeleton,
  Flex,
  Tooltip,
} from "@chakra-ui/react";
import { H2 } from "../../../Components/Headings/Headings";
import { Category } from "../../../types/documentation.types";
import SectionLink from "../../../Components/Links/SectionLink";
import {
  useSspControls,
  useSspScore,
  useSspStatuses,
} from "../../../hooks/queries.hooks";
import { useParams } from "react-router-dom";
import LoadingBar from "../../../Components/LoadingBar/LoadingBar";
import { AssessmentObjectiveSSPDisplayItem } from "../../../types/documentation.types";
import { CATEGORIES } from "../../../categories";
import InformationSystems from "./InformationSystems";

const MAX_SCORE = 110;

function getCategoryNameFromJSON(
  categories: Category[],
  sectionId: string
): string {
  const finalSecId = sectionId.trim().replace("[", "").replace("]", "").trim();
  let finalSecName = "";
  categories.forEach((cat) => {
    return cat.sections.forEach((section) => {
      if (section.sectionId === finalSecId) {
        finalSecName = " - " + section.sectionName;
        return;
      }
    });
  });
  return finalSecName;
}

function extractWordsWithinBrackets(inputString: string): string[] {
  const regex = /\[([^\]]+)]/g;
  const matches = inputString.match(regex);
  if (matches) {
    return matches.map((match) => match.slice(1, -1).trim());
  }
  return [];
}

export default function SystemSecurityPlanSection() {
  let controlsItem = [] as AssessmentObjectiveSSPDisplayItem[];
  const tenantId = useParams().tenantId as string;

  const {
    isLoading: statusesLoading,
    data: statuses,
    isError: isStatusesError,
    error: statusesError,
  } = useSspStatuses(tenantId);
  const {
    isLoading: controlsLoading,
    data: controls,
    isError: isControlsError,
    error: controlsError,
  } = useSspControls(tenantId);
  const aoIdToImplementedMap: { [key: string]: boolean } = {};
  const {
    isLoading: scoreLoading,
    data: score,
    error: scoreError,
  } = useSspScore(tenantId);

  statuses &&
    statuses.forEach((item) => {
      aoIdToImplementedMap[item.aoId] = item.implemented;
    });

  controls &&
    controls.forEach((control) => {
      const item = { ...control } as AssessmentObjectiveSSPDisplayItem;
      let newImplementation = item.implementation;
      // handle the implementation text
      const reg = /\[.*?\]/;
      let match;
      while ((match = reg.exec(newImplementation))) {
        const replacement = getCategoryNameFromJSON(
          CATEGORIES,
          match[0] as string
        );
        newImplementation = newImplementation.replace(match[0], replacement);
      }
      item.implementation = newImplementation;
      item.implemented = aoIdToImplementedMap[item.id];
      if (item.implemented) {
        item.implementedText = (
          <>
            <Badge colorScheme="green">Yes</Badge>
          </>
        );
      } else {
        item.implementedText = (
          <>
            <Badge colorScheme="red">No</Badge>
          </>
        );
      }

      // handle the artifacts
      const listOfArtifacts: string[] = extractWordsWithinBrackets(
        item.artifacts.trim()
      );
      item.artifactSection = (
        <>
          {listOfArtifacts.map((artifact) => {
            artifact = artifact.trim();
            artifact = artifact.replace("[", "");
            artifact = artifact.replace("]", "");
            artifact = artifact.trim();
            if (artifact) {
              return <SectionLink sectionId={artifact} key={artifact} />;
            }
            return <div key={artifact}></div>;
          })}
        </>
      );
      controlsItem.push(item);
    });

  return (
    <>
      <InformationSystems></InformationSystems>
      <H2 id="ssp">System Security Plan</H2>
      {(statusesLoading || controlsLoading) && <LoadingBar />}
      {isStatusesError && (
        <Text color="red">Error getting statuses: {statusesError.message}</Text>
      )}
      {isControlsError && (
        <Text color="red">Error getting controls: {controlsError.message}</Text>
      )}

      {controlsItem && (
        <>
          <Flex gap="16px" alignItems="center" marginTop={10}>
            <Text fontSize="20" fontWeight="semibold">
              Total Score:
            </Text>
            {score || scoreLoading ? (
              <Skeleton isLoaded={!scoreLoading}>
                <Badge fontSize="20" colorScheme="gray">
                  {score?.score}/{MAX_SCORE}
                </Badge>
              </Skeleton>
            ) : (
              <Tooltip label={scoreError?.message}>
                <Badge colorScheme="red">Error</Badge>
              </Tooltip>
            )}
          </Flex>

          <Table marginTop={10}>
            <Thead>
              <Tr>
                <Th>Name</Th>
                <Th>NIST SP 800-171 Control #</Th>
                <Th>NIST SP 800-171 R2 Control</Th>
                <Th>AO</Th>
                <Th>NIST SP 800-171A / CMMC Assessment Critera</Th>
                <Th>Implementation</Th>
                <Th>Artifacts</Th>
                <Th>AO Implemeted?</Th>
              </Tr>
            </Thead>
            <Tbody>
              {controlsItem.map((control) => (
                <Tr key={control.id}>
                  <Td>{control.cmmcName}</Td>
                  <Td>{control.controlNo}</Td>
                  <Td>{control.controlDescription}</Td>
                  <Td>{control.ao}</Td>
                  <Td>{control.assessmentCriteria}</Td>
                  <Td>{control.implementation}</Td>
                  <Td>{control.artifactSection}</Td>
                  <Td>{control.implementedText}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </>
      )}
    </>
  );
}
