import { H3 } from "../../../../../Components/Headings/Headings";
import { TextSection } from "../../../../../Components/Text/Text";

export default function CompanyCuiAccessPolicy() {
  return (
    <>
      <H3 id="company-cui-access-policy">Company CUI Access Policy</H3>
      <TextSection>
        Employees authorized to handle CUI are designated by the company's
        management and documented through their registration on the Atomus
        Secure Environment. Only approved employees may access CUI in any form,
        physical or digital through documented workflow in accordance with
        applicable data access controls.
      </TextSection>
      <TextSection>
        Any CUI that is stored in both paper and digital mediums must be
        accessed only within the company's facilities inside protected areas.
        These protected areas are physically guarded by physical safeguards such
        as locked doors, which guard these areas from unauthorized access.
        Company management controls and maintains any physical access devices to
        ensure that access control information is kept up to date and operated
        per the manufacturer's recommendations in order to assure that the
        physical security safeguards are suitable to the company's needs. This
        includes keeping any keys, combinations, and other physical access
        devices secured. The company's physical facility has physical access
        monitored by employees who can detect and respond to physical security
        incidents. This policy also dictates that support infrastructure for
        information systems are kept inside protected areas.
      </TextSection>
      <TextSection>
        All systems containing CUI must be identified with an asset control
        identifier, this includes removable system media and system outputs such
        as digital manufacturing machines which CUI is uploaded to as part of
        the manufacturing process. Given the use of the Atomus Secure
        Environment for storing and processing CUI, CUI is not stored
        unencrypted on company computers such as desktops and laptops.
        Information systems such as removable media and manufacturing machines
        that contain CUI must be kept in protected areas. Output devices such as
        printers are placed in areas where their use does not expose data to
        unauthorized individuals.
      </TextSection>
      <TextSection>
        Alternate worksites deemed necessary by company management to use CUI
        must have the same physical security requirements of the main facility.
        Alternate processing sites provide equivalent information security
        measures through use of the Atomus Secure Environment. Remote access of
        the Atomus Secure Environment is allowed only through authorized methods
        such as through an authorized workstation via browser or remote desktop
        protocol.
      </TextSection>
    </>
  );
}
