import { Table, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { H2, H3 } from "../../../Components/Headings/Headings";
import LinkCard from "../../../Components/Links/LinkCard";
import LoadingBar from "../../../Components/LoadingBar/LoadingBar";
import { TextSection } from "../../../Components/Text/Text";
import { useUsersQuery } from "../../../hooks/queries.hooks";

const ARTICLE_LINK =
  "https://www.microsoft.com/en-us/insidetrack/implementing-strong-user-authentication-with-windows-hello-for-business";

export default function MultiFactorAuthenticationSection() {
  const tenantId = useParams().tenantId as string;
  const { data, isLoading, isError, error } = useUsersQuery(tenantId);

  return (
    <>
      <H2 id="mfa">Multi-Factor Authentication</H2>
      <H3>Local Access</H3>
      <TextSection>
        <strong>Windows Hello</strong> enables{" "}
        <strong>Multi-Factor Authentication</strong> in several ways. Microsoft
        article explaining different ways of using Windows Hello.
      </TextSection>
      <LinkCard
        title="Implementing strong user authentication with Windows Hello for Business"
        link={ARTICLE_LINK}
      />
      <H3>User Multi-Factor Authentication Statuses</H3>
      {isLoading && <LoadingBar />}
      {isError && (
        <Text color="red">Error getting Aegis users: {error.message}</Text>
      )}
      {data && (
        <Table>
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>Email</Th>
              <Th>Default MFA Method</Th>
              <Th>MFA Registered</Th>
            </Tr>
          </Thead>
          <Tbody>
            {data.map((user) => (
              <Tr key={user.userPrincipalName}>
                <Td>{user.displayName}</Td>
                <Td>{user.userPrincipalName}</Td>
                <Td>
                  {user.mfaDetails
                    ?.userPreferredMethodForSecondaryAuthentication || "none"}
                </Td>
                <Td>{`${user.mfaDetails?.isMfaRegistered || "false"}`}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      )}
    </>
  );
}
