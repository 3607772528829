import { Table, Tag, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { H2, H3 } from "../../../Components/Headings/Headings";
import QueryBoilerplate from "../../../Components/QueryBoilerplate/QueryBoilerplate";
import { TextSection } from "../../../Components/Text/Text";
import { useLatestHealthchecks } from "../../../hooks/queries.hooks";

export default function AntiMalwareSolutionSection() {
  const tenantId = useParams().tenantId as string;
  const { data, isLoading, isError, error } = useLatestHealthchecks(tenantId);
  return (
    <>
      <H2 id="antimalware">Anti-Malware Solution</H2>
      <TextSection>
        Microsoft Defender for Endpoint is installed, configured and updated on
        all Aegis devices.
      </TextSection>
      <H3>Device Defender Status</H3>
      <QueryBoilerplate {...{ isError, error, isLoading }} />
      {data && (
        <Table>
          <Thead>
            <Tr>
              <Th>Device Name</Th>
              <Th>Defender Status</Th>
              <Th>Defender Output</Th>
            </Tr>
          </Thead>
          <Tbody>
            {data
              .filter((test) => test.stepName_s === "defender")
              .map((defenderTest) => {
                return (
                  <Tr key={defenderTest.deviceName_s}>
                    <Td>{defenderTest.deviceName_s}</Td>
                    <Td>
                      <Tag
                        colorScheme={
                          defenderTest.status_s === "success"
                            ? "green"
                            : "info"
                            ? "gray"
                            : "red"
                        }
                      >
                        {defenderTest.status_s}
                      </Tag>
                    </Td>
                    <Td>{defenderTest.data_s}</Td>
                  </Tr>
                );
              })}
          </Tbody>
        </Table>
      )}
    </>
  );
}
