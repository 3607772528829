import { useMsal } from "@azure/msal-react";
import { useLocation } from "react-router-dom";
import { MSAL_REDIRECT_PATH } from "../../constants/auth.constants";
import { IReactChildren } from "../../types/props.types";

/**
 * @description our custom version of the `msal-react` `AuthenticatedTemplate`. It will
 * only render `children` if the current msal instance has an active account set.
 */
export function AuthenticatedTemplate({ children }: IReactChildren) {
  const { instance } = useMsal();
  const location = useLocation();
  if (location.pathname === MSAL_REDIRECT_PATH) {
    return <></>;
  }
  if (instance.getActiveAccount()) {
    return <>{children}</>;
  }
  return <></>;
}

/**
 * @description our custom version of the `msal-react` `UnauthenticatedTemplate`. It will
 * only render `children` if the current msal instance's active account is null.
 */
export function UnauthenticatedTemplate({ children }: IReactChildren) {
  const { instance } = useMsal();
  const location = useLocation();
  if (location.pathname === MSAL_REDIRECT_PATH) {
    return <></>;
  }
  if (!instance.getActiveAccount()) {
    return <>{children}</>;
  }
  return <></>;
}
