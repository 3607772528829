import { H2 } from "../../../Components/Headings/Headings";
import SectionImage from "../../../Components/SectionImage/SectionImage";
import AegisNetworkDiagram from "../../../assets/AegisNetworkDiagram.png";
import { NotionCode, TextSection } from "../../../Components/Text/Text";
import { ListItem, OrderedList } from "@chakra-ui/react";

export default function NetworkDiagramSection() {
  return (
    <>
      <H2 id="networkdiagram">Network Diagram</H2>
      <SectionImage src={AegisNetworkDiagram} />
      <TextSection>
        The flow of information is show below on the basis of two Use Cases:
      </TextSection>
      <TextSection>
        <strong>
          Use Case: The user types in google.com in their browser:
        </strong>
      </TextSection>
      <OrderedList>
        <ListItem>
          The request goes from their device <NotionCode>AEGIS #1</NotionCode>{" "}
          throught the LAN using the Encrypted Wi-Fi Network.
        </ListItem>
        <ListItem>
          Then the <NotionCode>Wi-Fi</NotionCode> hits the{" "}
          <NotionCode>Firewall & Proxy</NotionCode> to verify if that request is
          allowed from that device.
        </ListItem>
        <ListItem>
          If it is, it reaches the Public Internet to load google.com.
        </ListItem>
      </OrderedList>
      <TextSection>
        <strong>
          Use Case: The user needs to access through the secure network
        </strong>
      </TextSection>
      <OrderedList>
        <ListItem>
          They connect <NotionCode>VPN</NotionCode> on their local device{" "}
          <NotionCode>AEGIS #1</NotionCode>
        </ListItem>
        <ListItem>
          Only <NotionCode>AEGIS</NotionCode> devices on the{" "}
          <NotionCode>VPN</NotionCode> for that specific users are the only
          devices which can access those Office 365 resources like Mail and
          OneDrive.
        </ListItem>
      </OrderedList>
    </>
  );
}
