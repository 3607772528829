import { Table, Thead, Tr, Th, Tbody, Td, Tag } from "@chakra-ui/react";
import { Device } from "../../../../../types/atomus-internal-apis.types";

export default function DevicesTable({
  devices,
}: Readonly<{ devices: Device[] }>) {
  return (
    <Table>
      <Thead>
        <Tr>
          <Th>Device Name</Th>
          <Th>Registered Users</Th>
          <Th>Operating System</Th>
          <Th>Enrollment Type</Th>
          <Th>Is Compliant</Th>
        </Tr>
      </Thead>
      <Tbody>
        {devices.map((device) => (
          <Tr key={device.id}>
            <Td>{device.displayName}</Td>
            <Td>
              {device.registeredUsers
                ?.map((user) => user.displayName)
                .join(", ")}
            </Td>
            <Td>{device.operatingSystem}</Td>
            <Td>{device.enrollmentType}</Td>
            <Td>
              <Tag colorScheme={device.isCompliant ? "green" : "red"}>
                {`${device.isCompliant}`}
              </Tag>
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
}
