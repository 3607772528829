import { HStack, Tag, Text } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import BoxContainer from "../../../Components/Boxes/BoxContainer";
import { H2, H3 } from "../../../Components/Headings/Headings";
import LoadingBar from "../../../Components/LoadingBar/LoadingBar";
import { TextSection } from "../../../Components/Text/Text";
import { useDeviceVulnerabilities } from "../../../hooks/queries.hooks";

const getSeverityColorScheme = (severity: string) => {
  switch (severity) {
    case "Low":
      return "green";
    case "Medium":
      return "orange";
    default:
      return "red";
  }
};

export default function DeviceVulnerabilitiesSection() {
  const tenantId = useParams().tenantId as string;
  const { data, isError, error, isLoading } =
    useDeviceVulnerabilities(tenantId);
  return (
    <>
      <H2 id="devicevulnerabilities">Device Vulnerabilities</H2>
      {isLoading && <LoadingBar />}
      {isError && <Text color="red">{error.message}</Text>}
      {data &&
        data.map((vuln) => (
          <BoxContainer key={vuln.cveId}>
            <H3>{vuln.cveId}</H3>
            <TextSection>{vuln.description}</TextSection>
            <HStack marginY={2} alignItems="center">
              <Text fontWeight="semibold">Severity:&nbsp;</Text>
              <Tag colorScheme={getSeverityColorScheme(vuln.severity)}>
                {vuln.severity}
              </Tag>
            </HStack>
            <HStack marginY={2} alignItems="center">
              <Text fontWeight="semibold">Exposed machines:</Text>
              <Text>{vuln.exposedMachines.join(", ")}</Text>
            </HStack>
          </BoxContainer>
        ))}
    </>
  );
}
