import AtomusAegisPlatformSection from "./Pages/AtomusPage/Sections/AtomusAegisPlatformSection";
import SdlcSection from "./Pages/AtomusPage/Sections/SdlcSection";
import AuditableReviewEventsSection from "./Pages/AuthorizedListsPage/Sections/AuditableReviewEventsSection";
import AuthorizedDevicesSection from "./Pages/AuthorizedListsPage/Sections/AuthorizedDevicesSection";
import AuthorizedUsersSection from "./Pages/AuthorizedListsPage/Sections/AuthorizedUsersSection/AuthorizedUsersSection";
import CompanySpecificPolicyValuesSection from "./Pages/AuthorizedListsPage/Sections/CompanySpecificPolicyValuesSection";
import FunctionsPortsAndProtocolsSection from "./Pages/AuthorizedListsPage/Sections/FunctionsPortsAndProtocolsSection";
import ProcessesOnBehalfOfAuthorizedUserSection from "./Pages/AuthorizedListsPage/Sections/ProcessesOnBehalfOfAnAuthorizedUserSection";
import UsbsAndPortableMediaSection from "./Pages/AuthorizedListsPage/Sections/UsbsAndPortableMediaSection";
import WhitelistedApplicationsSection from "./Pages/AuthorizedListsPage/Sections/WhitelistedApplicationsSection";
import WhitelistedIpsSection from "./Pages/AuthorizedListsPage/Sections/WhitelistedIpsSection";
import AntiMalwareSolutionSection from "./Pages/ControlsPage/Sections/AntiMalwareSolutionSection";
import BackupSection from "./Pages/ControlsPage/Sections/BackupSection";
import CuiEncryptionLabelsSection from "./Pages/ControlsPage/Sections/CuiEncryptionLabelsSection";
import DeviceVulnerabilitiesSection from "./Pages/ControlsPage/Sections/DeviceVulnerabilitiesSection";
import IdentityDomainEvidenceSection from "./Pages/ControlsPage/Sections/IdentityDomainEvidenceSection";
import InformationSystems from "./Pages/ControlsPage/Sections/InformationSystems";
import MultiFactorAuthenticationSection from "./Pages/ControlsPage/Sections/MultiFactorAuthenticationSection";
import NonVendorSupportedProductsSection from "./Pages/ControlsPage/Sections/NonVendorSupportedProductsSection";
import RemoteAccessSection from "./Pages/ControlsPage/Sections/RemoteAccessSection";
import SecurityControlsSection from "./Pages/ControlsPage/Sections/SecurityControlsSection/SecurityControlsSection";
import SystemSecurityPlanSection from "./Pages/ControlsPage/Sections/SystemSecurityPlanSection";
import UsersAndTheirRolesSection from "./Pages/ControlsPage/Sections/UsersAndTheirRolesSection";
import VpnSection from "./Pages/ControlsPage/Sections/VpnSection/VpnSection";
import WiFiStandardSection from "./Pages/ControlsPage/Sections/WiFiStandardSection";
import AuditDashboardSection from "./Pages/DocumentsPage/Sections/AuditDashboardSection";
import ConfigurationManagerSection from "./Pages/DocumentsPage/Sections/ConfigurationManagerSection/ConfigurationManagerSection";
import EmployeeHandbookSection from "./Pages/DocumentsPage/Sections/EmployeeHandbookSection/EmployeeHandbookSection";
import CompanyCuiAccessPolicy from "./Pages/DocumentsPage/Sections/EmployeeHandbookSection/Policies/CompanyCuiAccessPolicy";
import CompanyMediaSanitizationPolicy from "./Pages/DocumentsPage/Sections/EmployeeHandbookSection/Policies/CompanyMediaSanitizationPolicy";
import CompanyVisitorPolicy from "./Pages/DocumentsPage/Sections/EmployeeHandbookSection/Policies/CompanyVisitorPolicy";
import IncidentResponsePolicy from "./Pages/DocumentsPage/Sections/EmployeeHandbookSection/Policies/IncidentResponsePolicy";
import PhysicalSecurityPolicies from "./Pages/DocumentsPage/Sections/EmployeeHandbookSection/Policies/PhysicalSecurityPolicies";
import PublicInformationPolicy from "./Pages/DocumentsPage/Sections/EmployeeHandbookSection/Policies/PublicInformationPolicy";
import RiskManagementPolicy from "./Pages/DocumentsPage/Sections/EmployeeHandbookSection/Policies/RiskManagementPolicy";
import UseOfCompanyInformationSystemsPolicy from "./Pages/DocumentsPage/Sections/EmployeeHandbookSection/Policies/UseOfCompanyInformationSystemsPolicy";
import NetworkDiagramSection from "./Pages/DocumentsPage/Sections/NetworkDiagramSection";
import PoamSection from "./Pages/DocumentsPage/Sections/PoamSection";
import PrivilegedUsersAndRolesSection from "./Pages/DocumentsPage/Sections/PrivilegedUsersAndRolesSection";
import PrivilegedUsersContactSection from "./Pages/DocumentsPage/Sections/PrivilegedUsersContactSection";
import ReviewItemsSection from "./Pages/DocumentsPage/Sections/ReviewItemsSection";
import SharedResponsibilitySection from "./Pages/DocumentsPage/Sections/SharedResponsibilityMatrix/SharedResponsibilityMatrixSection";
import ThreatsAndAlertsSection from "./Pages/DocumentsPage/Sections/ThreatsAndAlertsSection";
import TrainingEvidenceSection from "./Pages/TrainingPage/Sections/TrainingEvidenceSection";
import TrainingSlidesSection from "./Pages/TrainingPage/Sections/TrainingSlidesSection";

export const CATEGORIES = [
  {
    categoryName: "Controls",
    categoryId: "controls",
    sections: [
      {
        sectionId: "ssp",
        sectionName: "[1] System Security Plan NIST 800-171",
        element: SystemSecurityPlanSection,
      },
      {
        sectionId: "identitydomainevidence",
        sectionName: "[2] Identity Domain Evidence",
        element: IdentityDomainEvidenceSection,
      },
      {
        sectionId: "usersandtheirroles",
        sectionName: "[3] Users & Roles",
        element: UsersAndTheirRolesSection,
      },
      {
        sectionId: "securitycontrols",
        sectionName: "[4] Security Controls",
        element: SecurityControlsSection,
      },
      {
        sectionId: "cuiencryptionlabels",
        sectionName: "[5] CUI Encryption Labels",
        element: CuiEncryptionLabelsSection,
      },
      {
        sectionId: "mfa",
        sectionName: "[6] Multi-Factor Authentication",
        element: MultiFactorAuthenticationSection,
      },
      {
        sectionId: "vpn",
        sectionName: "[7] VPN for Atomus Aegis devices",
        element: VpnSection,
      },
      {
        sectionId: "wifi",
        sectionName: "[8] Wi-Fi Standard",
        element: WiFiStandardSection,
      },
      {
        sectionId: "backup",
        sectionName: "[9] Backup",
        element: BackupSection,
      },
      {
        sectionId: "nonvendorsupportedproducts",
        sectionName: "[10] Non-Vendor Supported Products",
        element: NonVendorSupportedProductsSection,
      },
      {
        sectionId: "remoteaccess",
        sectionName: "[11] Remote Access",
        element: RemoteAccessSection,
      },
      {
        sectionId: "antimalware",
        sectionName: "[12] Anti-Malware Solution",
        element: AntiMalwareSolutionSection,
      },
      {
        sectionId: "devicevulnerabilities",
        sectionName: "[13] Device Vulnerabilities",
        element: DeviceVulnerabilitiesSection,
      },
    ],
  },
  {
    categoryName: "Authorized Lists",
    categoryId: "authorizedlists",
    sections: [
      {
        sectionId: "authorizedusers",
        sectionName: "[14] Authorized Users",
        element: AuthorizedUsersSection,
      },
      {
        sectionId: "authorizeddevices",
        sectionName: "[15] Authorized Devices",
        element: AuthorizedDevicesSection,
      },
      {
        sectionId: "processesonbehalfofauthorizedusers",
        sectionName: "[16] Processes on behalf of Authorized User",
        element: ProcessesOnBehalfOfAuthorizedUserSection,
      },
      {
        sectionId: "whitelistedapplications",
        sectionName: "[17] Whitelisted Applications",
        element: WhitelistedApplicationsSection,
      },
      {
        sectionId: "whitelistedips",
        sectionName: "[18] Whitelisted IPs",
        element: WhitelistedIpsSection,
      },
      {
        sectionId: "companyspecificpolicyvalues",
        sectionName: "[19] Company Specific Policy Values",
        element: CompanySpecificPolicyValuesSection,
      },
      {
        sectionId: "functionsportsprotocols",
        sectionName: "[20] Functions, Ports & Protocols",
        element: FunctionsPortsAndProtocolsSection,
      },
      {
        sectionId: "auditablereviewevents",
        sectionName: "[21] Auditable Review Events",
        element: AuditableReviewEventsSection,
      },
      {
        sectionId: "usbsandportablemedia",
        sectionName: "[22] USBs and Portable Media",
        element: UsbsAndPortableMediaSection,
      },
    ],
  },
  {
    categoryName: "Atomus",
    categoryId: "atomus",
    sections: [
      {
        sectionId: "sdlc",
        sectionName: "[23] SDLC Update Environments",
        element: SdlcSection,
      },
      {
        sectionId: "atomusaegis",
        sectionName: "[24] Atomus Aegis Platform",
        element: AtomusAegisPlatformSection,
      },
    ],
  },
  {
    categoryName: "Training",
    categoryId: "training",
    sections: [
      {
        sectionId: "trainingslides",
        sectionName: "[25] Training Slides",
        element: TrainingSlidesSection,
      },
      {
        sectionId: "trainingevidence",
        sectionName: "[26] Training Evidence",
        element: TrainingEvidenceSection,
      },
    ],
  },
  {
    categoryName: "Documents",
    categoryId: "documents",
    sections: [
      {
        sectionId: "poam",
        sectionName: "[27] POA&M",
        element: PoamSection,
      },
      {
        sectionId: "employeehandbook",
        sectionName: "[28] Employee Handbook",
        element: EmployeeHandbookSection,
      },
      {
        sectionId: "company-cui-access-policy",
        sectionName: "[29] Employee Handbook CUI Access Policy",
        element: CompanyCuiAccessPolicy,
      },
      {
        sectionId: "physical-security-policies",
        sectionName: "[30] Physical Security Policies",
        element: PhysicalSecurityPolicies,
      },
      {
        sectionId: "public-information-policy",
        sectionName: "[31] Public Information Policy",
        element: PublicInformationPolicy,
      },
      {
        sectionId: "use-of-company-information-systems-policy",
        sectionName: "[32] Use Of Company Information Systems Policy",
        element: UseOfCompanyInformationSystemsPolicy,
      },
      {
        sectionId: "incident-response-policy",
        sectionName: "[33] Incident Response Policy",
        element: IncidentResponsePolicy,
      },
      {
        sectionId: "company-media-sanitization-policy",
        sectionName: "[34] Company Media Sanitization Policy",
        element: CompanyMediaSanitizationPolicy,
      },
      {
        sectionId: "company-visitor-policy",
        sectionName: "[35] Company Visitor Policy",
        element: CompanyVisitorPolicy,
      },
      {
        sectionId: "risk-management-policy",
        sectionName: "[36] Risk Management Policy",
        element: RiskManagementPolicy,
      },
      {
        sectionId: "infossp",
        sectionName: "[37] Information about Systems",
        element: InformationSystems,
      },
      {
        sectionId: "networkdiagram",
        sectionName: "[38] Network Diagram",
        element: NetworkDiagramSection,
      },
      {
        sectionId: "configmanager",
        sectionName: "[39] Configuration Manager Dashboards",
        element: ConfigurationManagerSection,
      },
      {
        sectionId: "threatsandalerts",
        sectionName: "[40] Threats & Alerts",
        element: ThreatsAndAlertsSection,
      },
      {
        sectionId: "auditdashboard",
        sectionName: "[41] Audit Dashboard",
        element: AuditDashboardSection,
      },
      {
        sectionId: "reviewitems",
        sectionName: "[42] Review Items",
        element: ReviewItemsSection,
      },
      {
        sectionId: "privilegedusers",
        sectionName:
          "[43] Privileged Users - Contact and Reporting Information",
        element: PrivilegedUsersContactSection,
      },
      {
        sectionId: "privilegedusersroles",
        sectionName: "[44] Privileged Users & Roles",
        element: PrivilegedUsersAndRolesSection,
      },
      {
        sectionId: "srm",
        sectionName: "[45] Shared Responsibility Matrix",
        element: SharedResponsibilitySection,
      },
    ],
  },
];
