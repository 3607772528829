import { createContext } from "react";

interface IAuthContext {
  /**
   * @description the current environment (`true` means the msal instance is the Azure
   * government client, `false` means it is the Azure commercial client).
   */
  isGov: boolean;
  /**
   * @description setter for the `isGov` value.
   */
  setIsGov: React.Dispatch<React.SetStateAction<boolean>>;
}

const defaultValue: IAuthContext = {
  isGov: false,
  setIsGov: () => {
    throw new Error("You must wrap your component in an AuthProvider");
  },
};

export default createContext(defaultValue);
