import { Text } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import BoxContainer from "../../../Components/Boxes/BoxContainer";
import { H2, H3 } from "../../../Components/Headings/Headings";
import SectionLink from "../../../Components/Links/SectionLink";
import LoadingBar from "../../../Components/LoadingBar/LoadingBar";
import { TextSection } from "../../../Components/Text/Text";
import { useOfficeActivityQuery } from "../../../hooks/queries.hooks";

export default function AuditDashboardSection() {
  const tenantId = useParams().tenantId as string;
  const { data, isLoading, isError, error } = useOfficeActivityQuery(tenantId);
  return (
    <>
      <H2 id="auditdashboard">Audit Dashboard</H2>
      <TextSection>
        Currently, all the Audit Logs are protected and can only be viewed using
        protected dashboard for the <strong>Security Admin</strong>.
      </TextSection>
      <TextSection>
        The <strong>Company Admin</strong> being a{" "}
        <SectionLink sectionId="privilegedusersroles" /> has read-only access to
        the audit logs.
      </TextSection>
      <H3>Office Activity Logs</H3>
      {isLoading && <LoadingBar />}
      {isError && (
        <Text color="red">
          Error getting office activity logs: {error.message}
        </Text>
      )}
      {data &&
        data.map((log, idx) => (
          <BoxContainer key={idx}>
            <pre style={{ whiteSpace: "pre-wrap" }}>
              {JSON.stringify(log, null, 4)}
            </pre>
          </BoxContainer>
        ))}
    </>
  );
}
