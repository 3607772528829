import { H2, H3 } from "../../../Components/Headings/Headings";
import { ImageSubText, TextSection } from "../../../Components/Text/Text";
import AzSentinelAnalyticsScreenshot from "../../../assets/screenshots/AzSentinelAnalyticsScreenshot.png";
import AZSentinelHuntingScreenshot from "../../../assets/screenshots/AzSentinelHuntingScreenshot.png";
import SectionImage from "../../../Components/SectionImage/SectionImage";

export default function ThreatsAndAlertsSection() {
  return (
    <>
      <H2 id="threatsandalerts">Threats and Alerts</H2>
      <TextSection>
        All the alerts are continously monitored and notified to the{" "}
        <strong>Security Admin</strong> from <strong>Atomus Corporation</strong>
        .
      </TextSection>
      <SectionImage src={AzSentinelAnalyticsScreenshot} />
      <ImageSubText>
        Screenshot of Azure Portal to show all the different alert indicators
        that are being continuously analyzed
      </ImageSubText>
      <H3>Investigation and hunting</H3>
      <TextSection>
        We can use Dashboards to investigate a particular case.
      </TextSection>
      <SectionImage src={AZSentinelHuntingScreenshot} />
      <ImageSubText>
        Screenshot of Azure Portal showing the investigation portal for any
        incident or case
      </ImageSubText>
      {[
        "All the internal security alerts.",
        "All the vulnerabilities are listed.",
        "All the risk mitigation templates exist in Azure Setinel for known risks.",
      ].map((text, idx) => (
        <TextSection key={idx}>{text}</TextSection>
      ))}
    </>
  );
}
