import { Table, Thead, Tr, Th, Tbody, Td, Text } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { H2 } from "../../../Components/Headings/Headings";
import LoadingBar from "../../../Components/LoadingBar/LoadingBar";
import { useSignInIps } from "../../../hooks/queries.hooks";

export default function WhitelistedIpsSection() {
  const tenantId = useParams().tenantId as string;
  const { data, isError, isLoading, error } = useSignInIps(tenantId);
  return (
    <>
      <H2 id="whitelistedips">Whitelisted IPs</H2>
      {isError && <Text color="red">{error.message}</Text>}
      {isLoading && <LoadingBar />}
      {data && (
        <>
          <Table>
            <Thead>
              <Tr>
                <Th>IP Address</Th>
                <Th>User Principal Name</Th>
              </Tr>
            </Thead>
            <Tbody>
              {data.map((ip, idx) => (
                <Tr key={idx}>
                  <Td>{ip.IPAddress}</Td>
                  <Td>{ip.UserPrincipalName}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </>
      )}
    </>
  );
}
