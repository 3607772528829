import { useMsal } from "@azure/msal-react";
import { Box, Button, Center, Text, VStack } from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import authContext from "../../contexts/auth.context";

export default function LoginPage() {
  const [loginInProgress, setLoginInProgress] = useState(false);
  const [switchInProgress, setSwitchInProgress] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const { isGov, setIsGov } = useContext(authContext);

  const { instance } = useMsal();

  const handleLogin = async () => {
    setLoginInProgress(true);
    setErrorMsg("");
    try {
      const res = await instance.loginPopup();
      instance.setActiveAccount(res.account);
    } catch (error) {
      setErrorMsg((error as Error).message || "an unknown error occurred");
    }
    setLoginInProgress(false);
  };

  const handleSwitchEnv = () => {
    setIsGov(!isGov);
  };

  // minor extra guard to make sure the instance is finished being set before the
  // user can click login after switching environments
  useEffect(() => {
    setSwitchInProgress(false);
  }, [instance]);

  return (
    <Box height="100vh">
      <Center marginY={4} marginTop="30vh">
        <VStack spacing={4} align="stretch">
          <Text fontSize="xl">Login to Aegis Dashboard</Text>
          <Button
            onClick={handleSwitchEnv}
            colorScheme="blue"
            isDisabled={switchInProgress}
            isLoading={loginInProgress}
          >
            Switch environment
          </Button>
          <Button
            onClick={handleLogin}
            colorScheme="blue"
            isDisabled={switchInProgress}
            isLoading={loginInProgress}
          >
            Login to {isGov ? "gov" : "commercial"}
          </Button>
        </VStack>
      </Center>
      {errorMsg && (
        <Center>
          <Text color="red">{errorMsg}</Text>
        </Center>
      )}
    </Box>
  );
}
