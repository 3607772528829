import { Table, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { H2, H3 } from "../../../Components/Headings/Headings";
import SectionLink from "../../../Components/Links/SectionLink";
import LoadingBar from "../../../Components/LoadingBar/LoadingBar";
import { TextSection } from "../../../Components/Text/Text";
import { useUsersAndRolesQuery } from "../../../hooks/queries.hooks";

export default function PrivilegedUsersAndRolesSection() {
  const tenantId = useParams().tenantId as string;
  const { data, isLoading, isError, error } = useUsersAndRolesQuery(tenantId);
  return (
    <>
      <H2 id="privilegedusersroles">Privileged Users and Their Roles</H2>
      <TextSection>
        Users without roles in <SectionLink sectionId="usersandtheirroles" />{" "}
        are <strong>Non-Privileged Users</strong>.
      </TextSection>
      <H3>Privileged Users</H3>
      {isLoading && <LoadingBar />}
      {isError && (
        <Text color="red">
          Error getting users and their roles: {error.message}
        </Text>
      )}
      {data && (
        <Table>
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>Email</Th>
              <Th>AAD Roles</Th>
              <Th>ARM Roles</Th>
            </Tr>
          </Thead>
          <Tbody>
            {data
              .filter(
                (user) => user.aadRoles.length > 0 || user.armRoles.length > 0
              )
              .map((user) => (
                <Tr id={user.user.userPrincipalName}>
                  <Td>{user.user.displayName}</Td>
                  <Td>{user.user.userPrincipalName}</Td>
                  <Td>
                    {JSON.stringify(
                      user.aadRoles.map(
                        (role) => role.roleDefinition.displayName
                      ),
                      null,
                      4
                    )}
                  </Td>
                  <Td>
                    {JSON.stringify(
                      user.armRoles.map((role) => role.roleDefinition.roleName),
                      null,
                      4
                    )}
                  </Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
      )}
    </>
  );
}
