import { Table, Tbody, Td, Th, Thead, Tr, Text } from "@chakra-ui/react";
import { H2 } from "../../../Components/Headings/Headings";
import { usePoamItems } from "../../../hooks/queries.hooks";
import { useParams } from "react-router-dom";
import LoadingBar from "../../../Components/LoadingBar/LoadingBar";
import { CSVLink } from "react-csv";

export default function PoamSection() {
  const tenantId = useParams().tenantId as string;
  const { data, isError, error, isLoading } = usePoamItems(tenantId);

  return (
    <>
      <H2 id="poam">POA&M</H2>
      {isLoading && <LoadingBar />}
      {isError && <Text color="red">Error getting POA&M: {error.message}</Text>}
      {error && <Text color="red">Error getting POA&M: {error.message}</Text>}
      {data && <CSVLink data={data!}>Download me</CSVLink>}
      {data && (
        <Table>
          <Thead>
            <Tr>
              <Th>Weakness</Th>
              <Th>Assignee</Th>
              <Th>Milestone Completion Date</Th>
              <Th>Changes</Th>
              <Th>Scheduled Completion Date</Th>
              <Th>Status</Th>
            </Tr>
          </Thead>
          <Tbody>
            {data.map((control) => (
              <Tr key={control.aoId}>
                <Td>{control.weakness}</Td>
                <Td>{control.assignee}</Td>
                <Td>{control.milestoneCompletionDate}</Td>
                <Td>{control.changes}</Td>
                <Td>{control.scheduledCompletionDate}</Td>
                <Td>{control.status}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      )}
    </>
  );
}
