import { H3 } from "../../../../../Components/Headings/Headings";
import { TextSection } from "../../../../../Components/Text/Text";

export default function PublicInformationPolicy() {
  return (
    <>
      <H3 id="public-information-policy">Public Information Policy</H3>
      <TextSection>
        Only authorized employees may post information on publicly accessible
        information systems such as the company website. These authorized
        employees have gone through appropriate training from management to
        ensure that CUI and nonpublic information is not posted. Public
        information such as the website is reviewed annually by company
        management to ensure that CUI and non-public information is not posted.
        Prior to sharing proposed content on a website or other publicly
        accessible forum,<strong>Company Admin</strong> must review and approve
        the information being posted.
      </TextSection>
    </>
  );
}
