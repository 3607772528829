import { Card, LinkOverlay, Text } from "@chakra-ui/react";

interface ILinkCardProps {
  title: string;
  link: string;
}

export default function LinkCard({ title, link }: ILinkCardProps) {
  return (
    <Card padding={2} border="1px solid" borderColor="gray.300" marginY={2}>
      <LinkOverlay href={link} isExternal>
        <Text>{title}</Text>
        <Text size="xs" color="gray.400">
          {link}
        </Text>
      </LinkOverlay>
    </Card>
  );
}
