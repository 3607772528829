import { MsalAuthConfigs, MsftLoginUrls } from "../constants/auth.constants";

export const getAdminConsentLink = (
  tenantId: string,
  isGov: boolean
): string => {
  const baseUrl = isGov ? MsftLoginUrls.GOV : MsftLoginUrls.COMMERCIAL;
  const redirectUri =
    window.location.hostname === "localhost"
      ? `${window.location.protocol}//${window.location.hostname}:${window.location.port}/msal-redirect`
      : `${window.location.protocol}//${window.location.hostname}/msal-redirect`;
  const apiClientId = isGov
    ? MsalAuthConfigs.gov.auth.clientId
    : MsalAuthConfigs.commercial.auth.clientId;
  return `${baseUrl}/${tenantId}/adminconsent?client_id=${apiClientId}&redirect_uri=${redirectUri}`;
};
