import { Code, ListItem, UnorderedList } from "@chakra-ui/react";
import { H2 } from "../../../../Components/Headings/Headings";
import { BoldTextSection, TextSection } from "../../../../Components/Text/Text";
import DeviceHealthcheckEvidence from "./DeviceHealthcheckEvidence";
import RegKeyTable from "./RegKeyTable";
import GroupPolicyScreenshot from "../../../../assets/screenshots/GroupPolicyScreenshot.png";
import BitlockerGpScreenshot from "../../../../assets/screenshots/BitlockerGpScreenshot.png";
import SectionImage from "../../../../Components/SectionImage/SectionImage";
import LinkCard from "../../../../Components/Links/LinkCard";
import SectionDivider from "../../../../Components/SectionDivider/SectionDivider";
import SectionLink from "../../../../Components/Links/SectionLink";

export default function SecurityControlsSection() {
  return (
    <>
      <H2 id="securitycontrols">Security Controls</H2>
      <TextSection>
        The <strong>list of Group Policy Settings</strong> that need to be
        modified for Security Policies and Configurations:
      </TextSection>
      <BoldTextSection>Session Termination</BoldTextSection>
      <TextSection>
        <Code>
          Windows Components\Remote Desktop Services\Remote Desktop Session
          Host\Session Time Limits
        </Code>
      </TextSection>
      <BoldTextSection>Account Lockout</BoldTextSection>
      <TextSection>
        <Code>
          Computer Configuration\Windows Settings\Security Settings\Account
          Policies\Account Lockout Policy
        </Code>
      </TextSection>
      <BoldTextSection>Password Complexity</BoldTextSection>
      <TextSection>
        <Code>
          Computer Configuration\Windows Settings\Security Settings\Account
          Policies\Password Policy
        </Code>
      </TextSection>
      <SectionImage src={GroupPolicyScreenshot} />
      <BoldTextSection>Remote Session Settings</BoldTextSection>
      <TextSection>
        <Code>
          Computer Configuration\Policies\Administrative Templates\Windows
          Components\Remote Desktop Services\Remote Desktop Session Host\Session
          Time Limits
        </Code>
      </TextSection>

      <SectionDivider />

      <TextSection>
        The <strong>list of Registry Keys</strong> that need to be modified for
        Security Policies and Configurations:
      </TextSection>
      <BoldTextSection>BitLocker Encryption</BoldTextSection>
      <TextSection>
        <Code>
          HKEY_LOCAL_MACHINE\SYSTEM\CurrentControlSet\Control\BitLocker
        </Code>
      </TextSection>
      <LinkCard
        link="https://learn.microsoft.com/en-us/windows/security/information-protection/bitlocker/bitlocker-device-encryption-overview-windows-10"
        title="Overview of BitLocker Device Encryption in Windows"
      />
      <LinkCard
        link="https://www.itprotoday.com/strategy/q-how-can-i-force-windows-7-clients-use-bitlocker-go-writing-usb-devices"
        title="How can I force Windows 7 clients to use BitLocker To Go before writing to USB devices?"
      />
      <SectionImage src={BitlockerGpScreenshot} />
      <BoldTextSection>Time Synchronization using NTP</BoldTextSection>
      <TextSection>
        <Code>
          HKEY_LOCAL_MACHINE\SYSTEM\CurrentControlSet\Services\W32Time\TimeProviders\NtpServer
        </Code>
      </TextSection>
      <TextSection>
        Enabled parameter is <strong>1</strong>
        <br />
        Also, it can be a <strong>GPO</strong> too
        <br />
        <Code>
          Computer Configuration &gt; Administrative Templates &gt; System &gt;
          Windows Time Service &gt; Time Providers.
        </Code>
        <br />
        Enable the following policy settings:
      </TextSection>
      <UnorderedList>
        <ListItem>Configure Windows NTP Client: Enabled.</ListItem>
        <ListItem>Enable Windows NTP Client: Enabled.</ListItem>
        <ListItem>Enable Windows NTP Server: Enabled.</ListItem>
      </UnorderedList>
      <BoldTextSection>FIPS-Compliant Cryptography</BoldTextSection>
      <TextSection>
        <Code>
          HKEY_LOCAL_MACHINE-\SYSTEM\CurrentControlSet\Control\Lsa\FipsAlgorithmPolicy\Enabled
        </Code>
      </TextSection>
      <RegKeyTable />
      <DeviceHealthcheckEvidence />
      <SectionLink sectionId="cuiencryptionlabels" />
    </>
  );
}
