export const IntuneMobileDeviceTypes = [
  "winmo6",
  "nokia",
  "windowsphone",
  "wince",
  "winembedded",
  "iphone",
  "ipad",
  "ipod",
  "android",
  "hololens",
  "androidforwork",
  "blackberry",
  "palm",
];
