import { useParams } from "react-router-dom";
import BoxContainer from "../../../Components/Boxes/BoxContainer";
import { H2 } from "../../../Components/Headings/Headings";
import QueryBoilerplate from "../../../Components/QueryBoilerplate/QueryBoilerplate";
import { TextSection } from "../../../Components/Text/Text";
import { useUsbMountEvents } from "../../../hooks/queries.hooks";
import LoadingBar from "../../../Components/LoadingBar/LoadingBar";

export default function UsbsAndPortableMediaSection() {
  const tenantId = useParams().tenantId as string;
  const { data, isLoading, isError } = useUsbMountEvents(tenantId);
  return (
    <>
      <H2 id="usbsandportablemedia">USBs and Portable Media</H2>
      {isLoading && <LoadingBar />}
      {isError && <TextSection>N/A</TextSection>}
      {data &&
        data.map((usbEvent, idx) => (
          <BoxContainer key={idx}>
            <TextSection>
              <strong>Time generated: </strong>
              {usbEvent.TimeGenerated}
            </TextSection>
            <TextSection>
              <strong>Device name: </strong>
              {usbEvent.DeviceName}
            </TextSection>
            <TextSection>
              <strong>Action type: </strong>
              {usbEvent.ActionType}
            </TextSection>
            <TextSection>
              <strong>USB device manufacturer: </strong>
              {usbEvent.UsbDeviceVendor}
            </TextSection>
            <TextSection>
              <strong>USB device name: </strong>
              {usbEvent.UsbDeviceName}
            </TextSection>
            <TextSection>
              <strong>USB device id: </strong>
              {usbEvent.UsbDeviceId}
            </TextSection>
          </BoxContainer>
        ))}
    </>
  );
}
