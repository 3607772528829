import { Table, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { H2, H3 } from "../../../Components/Headings/Headings";
import LoadingBar from "../../../Components/LoadingBar/LoadingBar";
import { useDevicesAppsQuery } from "../../../hooks/queries.hooks";

export default function WhitelistedApplicationsSection() {
  const tenantId = useParams().tenantId as string;
  const { data, isLoading, isError, error, isIdle } =
    useDevicesAppsQuery(tenantId);
  return (
    <>
      <H2 id="whitelistedapplications">Whitelisted Applications</H2>
      <H3>Current Device Inventory</H3>
      {(isLoading || isIdle) && <LoadingBar />}
      {isError && (
        <Text color="red">Error getting device inventory: {error.message}</Text>
      )}
      {data && (
        <Table variant="striped">
          <Thead>
            <Tr>
              <Th>Device Name</Th>
              <Th>Registered Users</Th>
              <Th>Device Aplications</Th>
            </Tr>
          </Thead>
          <Tbody>
            {Object.entries(data).map(([deviceName, deviceData]) => (
              <Tr key={deviceName}>
                <Td>{deviceName}</Td>
                <Td>{deviceData.users.join(", ")}</Td>
                <Td>
                  <pre>{JSON.stringify(deviceData.applications, null, 4)}</pre>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      )}
    </>
  );
}
