import { H2 } from "../../../../Components/Headings/Headings";
import LinkCard from "../../../../Components/Links/LinkCard";
import SectionLink from "../../../../Components/Links/SectionLink";
import { TextSection } from "../../../../Components/Text/Text";
import AccessServer from "./AccessServer";
import VmConnection from "./VmConnection";

export default function VpnSection() {
  return (
    <>
      <H2 id="vpn">VPN for Atomus Aegis Devices</H2>
      <TextSection>
        All the Atomus Aegis devices can access information outside the external
        boundaries through a VPN. All the traffic from the{" "}
        <SectionLink sectionId="authorizeddevices" />
        is audited on the centralized log repository. Dashboard also allows
        companies to manage the rules.
      </TextSection>
      <TextSection>
        This uses Windows Defender for firewall and Atomus application to
        enforce the network filtering rules.
      </TextSection>
      <LinkCard
        link="https://docs.microsoft.com/en-us/windows/security/threat-protection/windows-firewall/windows-firewall-with-advanced-security"
        title="Windows Firewall Advanced Security"
      />
      <TextSection>
        This also uses a VPN along with Network Security Group, to allow/block
        all the network calls.
      </TextSection>
      <AccessServer />
      <VmConnection />
    </>
  );
}
