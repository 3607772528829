import { H3 } from "../../../../Components/Headings/Headings";
import ChildLink from "../../../../Components/Links/ChildLink";

export default function PolicyLinks() {
  return (
    <>
      <H3>Policies</H3>
      <ChildLink
        text="Use of Company Information Systems Policy"
        to="#use-of-company-information-systems-policy"
      />
      <ChildLink
        text="Company CUI Access Policy"
        to="#company-cui-access-policy"
      />
      <ChildLink text="Risk Management Policy" to="#risk-management-policy" />
      <ChildLink
        text="Public Information Policy"
        to="#public-information-policy"
      />
      <ChildLink
        text="Incident Response Policy"
        to="#incident-response-policy"
      />
      <ChildLink
        text="Company Media Sanitization Policy"
        to="#company-media-sanitization-policy"
      />
      <ChildLink text="Company Visitor Policy" to="#company-visitor-policy" />
      <ChildLink
        text="Physical Security Policies"
        to="#physical-security-policies"
      />
      <ChildLink
        text="Mobile and Personal Device Policy"
        to="#mobile-and-personal-device-policy"
      />
    </>
  );
}
