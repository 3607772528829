import { Heading } from "@chakra-ui/react";
import { IReactChildrenAndId } from "../../types/props.types";

export function H1({ children, id }: IReactChildrenAndId) {
  return (
    <Heading marginY={4} id={id} size="2xl">
      {children}
    </Heading>
  );
}

export function H2({ children, id }: IReactChildrenAndId) {
  return (
    <Heading size="lg" marginY={2} id={id} fontWeight="semibold">
      {children}
    </Heading>
  );
}

export function H3({ children, id }: IReactChildrenAndId) {
  return (
    <Heading size="md" marginY={1} id={id} fontWeight="semibold">
      {children}
    </Heading>
  );
}
