import { ListItem, UnorderedList } from "@chakra-ui/react";
import { H2, H3 } from "../../../Components/Headings/Headings";
import SectionLink from "../../../Components/Links/SectionLink";
import { TextSection } from "../../../Components/Text/Text";

export default function FunctionsPortsAndProtocolsSection() {
  return (
    <>
      <H2 id="functionsportsandprotocols">Functions, Ports and Protocols</H2>
      <H3>Restricted List</H3>
      <TextSection>There are no restricted items right now.</TextSection>
      <H3>Essential Functions</H3>
      <UnorderedList>
        <ListItem>
          Audit Records and Analysis tools enabling Incident Response Capability
          <UnorderedList>
            <ListItem>
              <SectionLink sectionId="auditablereviewevents" />
            </ListItem>
            <ListItem>
              <SectionLink sectionId="auditdashboard" />
            </ListItem>
            <ListItem>
              <SectionLink sectionId="threatsandalerts" />
            </ListItem>
          </UnorderedList>
        </ListItem>
        <ListItem>
          Security Functions
          <UnorderedList>
            <ListItem>
              <SectionLink sectionId="securitycontrols" />
            </ListItem>
          </UnorderedList>
        </ListItem>
        <ListItem>
          All Company Administrations Functions
          <UnorderedList>
            <ListItem>
              <SectionLink sectionId="usersandtheirroles" />
            </ListItem>
          </UnorderedList>
        </ListItem>
        <ListItem>
          Review and Updating the plans and documentations
          <UnorderedList>
            <ListItem>
              <SectionLink sectionId="reviewitems" />
            </ListItem>
          </UnorderedList>
        </ListItem>
      </UnorderedList>
    </>
  );
}
