import { H2, H3 } from "../../../Components/Headings/Headings";
import { TextSection } from "../../../Components/Text/Text";
import WindowsDaProfilePageScreenshot from "../../../assets/screenshots/WindowsDaProfilePageScreenshot.png";
import SectionImage from "../../../Components/SectionImage/SectionImage";
import { useParams } from "react-router-dom";
import { useLatestHealthchecks } from "../../../hooks/queries.hooks";
import QueryBoilerplate from "../../../Components/QueryBoilerplate/QueryBoilerplate";
import { Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";

export default function BackupSection() {
  const tenantId = useParams().tenantId as string;
  const { data, isError, error, isLoading } = useLatestHealthchecks(tenantId);

  return (
    <>
      <H2 id="backup">Backup</H2>
      <TextSection>
        Backup completely backs up the entire system to a cloud storage after
        encrypting it twice using 2 keys (one Azure-managed key and 1
        Customer-Managed key) using the Backup agent by Atomus. Atomus backs up
        the system using native tools to backup the data from the Operating
        System Drive. Atomus backups are just data backups, and not system
        backups.
      </TextSection>
      <SectionImage src={WindowsDaProfilePageScreenshot} />
      <H3>Backup Statuses</H3>
      <QueryBoilerplate {...{ isError, error, isLoading }} />
      {data && (
        <Table>
          <Thead>
            <Tr>
              <Th>Computer Name</Th>
              <Th>Username</Th>
              <Th>Backup Status</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {data
              .filter((hcTest) =>
                ["last-backup", "backupstatus"].includes(hcTest.stepName_s)
              )
              .map((hcTest) => {
                return (
                  <Tr key={hcTest.deviceName_s}>
                    <Td>{hcTest.deviceName_s}</Td>
                    <Td>{hcTest.username_s}</Td>
                    <Td>{hcTest.data_s}</Td>
                  </Tr>
                );
              })}
          </Tbody>
        </Table>
      )}
    </>
  );
}
