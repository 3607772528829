import { Text } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import BoxContainer from "../../../../Components/Boxes/BoxContainer";
import { H3 } from "../../../../Components/Headings/Headings";
import LoadingBar from "../../../../Components/LoadingBar/LoadingBar";
import { JsonText } from "../../../../Components/Text/Text";
import { useUpdateManagementLogs } from "../../../../hooks/queries.hooks";

export default function UpdateManagement() {
  const tenantId = useParams().tenantId as string;
  const { data, isLoading, isError } = useUpdateManagementLogs(tenantId);
  return (
    <>
      <H3>Update Management Logs</H3>
      {isLoading && <LoadingBar />}
      {isError && <Text>N/A</Text>}
      {data &&
        data.map((log, idx) => (
          <BoxContainer key={idx}>
            <JsonText>{JSON.stringify(log, null, 4)}</JsonText>
          </BoxContainer>
        ))}
    </>
  );
}
