import { Center, Text } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import DocumentationPage from "../../Components/DocumentationPage/DocumentationPage";
import LoadingBar from "../../Components/LoadingBar/LoadingBar";
import PageContainer from "../../Components/PageContainer/PageContainer";
import { useCompanyInfoQuery } from "../../hooks/queries.hooks";
import GeneralInfoSection from "./Sections/GeneralInfoSection";

export default function CompanyInfoPage() {
  const tenantId = useParams().tenantId as string;
  const { data, isLoading, isError, error } = useCompanyInfoQuery(tenantId);

  if (isLoading) {
    return (
      <PageContainer>
        <LoadingBar />
      </PageContainer>
    );
  }

  if (isError) {
    return (
      <Center margin={100}>
        <Text color="red">Error fetching company info: {error.message}</Text>
      </Center>
    );
  }

  if (!data) {
    return (
      <Center margin={100}>
        <Text color="red">
          {`Could not get data even though query is not loading and there is no
          error (check that data is present in the database).
          Current running on ${process.env.REACT_APP_INTERNAL_BASE_URL}`}
        </Text>
      </Center>
    );
  }
  return (
    <DocumentationPage
      pageName="[0] Organizational Information"
      sectionId="companyinfo"
    >
      <GeneralInfoSection companyInfo={data} />
    </DocumentationPage>
  );
}
