import { H2 } from "../../../Components/Headings/Headings";
import { TextSection } from "../../../Components/Text/Text";

export default function InformationSystems() {
  return (
    <>
      <H2 id="infossp">Information about Systems</H2>
      <TextSection>Information</TextSection>
    </>
  );
}
