import { H3 } from "../../../Components/Headings/Headings";
import SectionLink from "../../../Components/Links/SectionLink";
import { TextSection } from "../../../Components/Text/Text";

export default function ReviewItemsSection() {
  return (
    <>
      <H3 id="reviewitems">Review Items</H3>
      <TextSection>
        <SectionLink sectionId="poam" /> is reviewed annually by{" "}
        <strong>Atomus Corporation</strong> with the{" "}
        <strong>Company Admin</strong>.
      </TextSection>
      <TextSection>
        All <SectionLink sectionId="authorizeddevices" />
        and <SectionLink sectionId="authorizedusers" />
        are reviewed by the <strong>Company Admin</strong> as well as the{" "}
        <strong>Security Admin</strong>.
      </TextSection>
      <TextSection>
        <SectionLink sectionId="ssp" />
        is reviewed annually by <strong>Atomus Corporation </strong> with the{" "}
        <strong>Company Admin</strong>.
      </TextSection>
      <TextSection>
        <SectionLink sectionId="trainingslides" />
        is reviewed with the <strong>employees</strong> annually.
      </TextSection>
      <TextSection>
        <SectionLink sectionId="threatsandalerts" />
        and Risk Assessment is done annually by <strong>
          Security Admin
        </strong>{" "}
        and the <strong>Company Admin</strong>.
      </TextSection>
      <TextSection>
        <SectionLink sectionId="backup" /> is tested every 6 months by the{"  "}
        <strong>Security Admin</strong> and the <strong>Company Admin</strong>.
      </TextSection>
      <TextSection>
        <SectionLink sectionId="securitycontrols" /> is monitored and reviewed
        by <strong>Atomus Corporation</strong> annually.
      </TextSection>
      <TextSection>
        <SectionLink sectionId="auditablereviewevents" />
        is monitored and reviewed by Atomus Corporation annually.
      </TextSection>
      <TextSection>
        <SectionLink sectionId="whitelistedapplications" />
        is reviewed by <strong>Company Admin</strong> and{" "}
        <strong>Atomus Corporation</strong> annually.
      </TextSection>
      <TextSection>
        Incident Response is tested and reviewed by{" "}
        <strong>Atomus Corporation</strong>, <strong>Company Admin </strong> and{" "}
        <strong>Employees</strong> annually.
      </TextSection>
      <TextSection>
        Baseline Configurations is reviewed every 6 months by the{" "}
        <strong>Security Admin</strong> at <strong>Atomus Corporation</strong>.
      </TextSection>
    </>
  );
}
