import { H3 } from "../../../../../Components/Headings/Headings";
import { TextSection } from "../../../../../Components/Text/Text";

export default function UseOfCompanyInformationSystemsPolicy() {
  return (
    <>
      <H3 id="use-of-company-information-systems-policy">
        Use of Company Information Systems Policy
      </H3>
      <TextSection>
        Controlled Unclassified information (CUI) is to only be accessed inside
        the Atomus Secure Environment. CUI must follow the approved flow from
        the Atomus Secure Environment to all authorized personnel and authorized
        devices.
      </TextSection>
      <TextSection>
        {/* TODO: link to SSP (see notion) */}
        Employee access to information systems containing CUI is controlled by
        management. Individuals requiring access to CUI are screened as part of
        their hiring to the company and onboarding to Atomus Secure Environment
        by company management. When an employee is transferred or terminated,
        the company will disable information system access within 24 hours. As
        part of this process, the company management will review electronic and
        physical access permissions. The company will also notify Atomus to
        change access control permissions to the Atomus Secure Environment
        within these 24 hours. Users on the Atomus Secure Environment will only
        have 1 account, the environment is made to enforce least privilege
        access control policies. Users must not share credentials with anyone
        else. In order to make privileged changes, company management will need
        to contact Atomus.
      </TextSection>
      <TextSection>
        Baseline configurations of company information systems are developed,
        documented and maintained by Atomus for each secure environment used by
        the company. Baseline configurations include software versions and patch
        level, configuration parameters, network information including
        topologies, and communications with connected systems. Base line
        configurations are updated according to security risk and software
        changes. These baseline configurations are developed and approved in
        conjunction with the <strong>Security Admin</strong> at Atomus who
        manages these systems for the company. Deviations from baseline
        configurations are documented within the Atomus platform. Company
        management determines the allowed software programs authorized to
        execute on company information systems in conjunction with Atomus who
        documents and monitors the software programs used inside the Atomus
        Secure Environment. This list is reviewed annually by company
        management. Atomus engineers are the only people authorized, approved,
        and documented by the company to make changes to the information system
        the company uses. System services within the Atomus Secure Environment
        is reviewed on a regular basis to determine what is essential for the
        function of the system.
      </TextSection>
      <TextSection>
        The collaborative devices need to be approved by the{" "}
        <strong>Company Admin</strong>.
      </TextSection>
      <TextSection>
        The use of non-essential programs, ports, functions and services can
        only be done when approved by the <strong>Company Admin</strong> and
        enabled by the <strong>Security Admin</strong> from Atomus. All the
        essential programs, ports, functions and services have been defined by
        the Company working with the <strong>Security Admin</strong>.
      </TextSection>
      <TextSection>
        <strong>Portable storage devices</strong> devices may not be used to
        take information off company information systems without authorization
        from management. Authorized individuals and marked portable storage
        devices are able to receive information from the Atomus Secure
        Environment but are restricted to sharing that information only with
        manufacturing machines that are air gapped on the company's shop floor
        for the purpose of producing items for authorized jobs. The use of
        writable, removable media is restricted on the Atomus Secure Environment
        in accordance with documented company workflows. Removable media is
        allowed only when following existing workflows approved my management.
        Removable media used in workflows that include the handling of CUI must
        support physical encryption and cryptographic mechanisms complying with
        FIPS 140-2. Key vaulting must also be used on the removable media in
        order to ensure recoverability. Only management can designate and
        approve portable storage and removable media which is allowed to store
        CUI.
      </TextSection>
    </>
  );
}
