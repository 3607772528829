import { useMsal } from "@azure/msal-react";
import { Button, Flex, HStack, Spacer, Text } from "@chakra-ui/react";
import { useState, useEffect, useContext } from "react";
import { useQueryClient } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import authContext from "../../contexts/auth.context";

export default function Navbar() {
  const tenantId = useParams().tenantId as string;
  const [accountName, setAccountName] = useState<string>("");
  const { instance } = useMsal();
  const { isGov, setIsGov } = useContext(authContext);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogout = async () => {
    queryClient.removeQueries();
    queryClient.clear();
    await instance.logoutPopup({ account: instance.getActiveAccount() });
    setIsGov(!isGov);
  };

  const handleSwitch = () => {
    setIsGov(!isGov);
    navigate("/tenants");
  };

  useEffect(() => {
    const account = instance.getActiveAccount();
    if (account) {
      setAccountName(account.username);
    }
  }, [instance]);

  return (
    <Flex alignItems="baseline">
      <HStack marginX={8} marginY={4} alignItems="baseline" spacing={6}>
        <Text fontSize="xl" fontWeight="semibold">
          Documentation - v3.0
        </Text>
        <Button
          onClick={() => navigate("/tenants")}
          variant="ghost"
          colorScheme="blue"
        >
          Tenants
        </Button>
        {tenantId && location.pathname !== `/tenants/${tenantId}` && (
          <Button
            onClick={() => navigate(`/tenants/${tenantId}`)}
            variant="ghost"
            colorScheme="blue"
          >
            Table of Contents
          </Button>
        )}
      </HStack>
      <Spacer />
      <HStack marginX={8} marginY={4} alignItems="baseline" spacing={4}>
        <Text>{accountName}</Text>
        <Button onClick={handleSwitch} size="sm">
          Switch to {isGov ? "commercial" : "gov"}
        </Button>
        <Button onClick={handleLogout} size="sm">
          Logout
        </Button>
      </HStack>
    </Flex>
  );
}
