import { Box, Flex, Text } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { H2 } from "../../../Components/Headings/Headings";
import LoadingBar from "../../../Components/LoadingBar/LoadingBar";
import { TextSection } from "../../../Components/Text/Text";
import { useDevicesQuery } from "../../../hooks/queries.hooks";
import DevicesTable from "./components/DevicesTable/DevicesTable";

export default function AuthorizedDevicesSection() {
  const tenantId = useParams().tenantId as string;
  const { data, isLoading, isError, error } = useDevicesQuery(tenantId);

  return (
    <Flex flexDir="column" gap="24px">
      <Box>
        <H2 id="authorizeddevices">Authorized Devices</H2>
        <TextSection>
          Every user has to join their device to the orgzanition with the MDM
          service - Microsoft Intune to make it an authorized device, so that
          all the configurations can be pulled in appropriately.
        </TextSection>
        <TextSection>
          All the devices get joined to the organization as shown here.
        </TextSection>
        {isLoading && <LoadingBar />}
        {isError && (
          <Text color="red">Error getting devices: {error.message}</Text>
        )}
        {data && (
          <DevicesTable
            devices={data.filter((device) =>
              device.registeredUsers?.find((user) => user.existsInDB)
            )}
          />
        )}
      </Box>
      {data?.find((device) => device.registeredUsers?.length === 0) && (
        <Box>
          <H2>Currently in Review</H2>
          {data && (
            <DevicesTable
              devices={data.filter(
                (device) => device.registeredUsers?.length === 0
              )}
            />
          )}
        </Box>
      )}
    </Flex>
  );
}
