import { Box, Flex, Stat, StatLabel, StatNumber, Text } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import BoxContainer from "../../../../Components/Boxes/BoxContainer";
import { H3 } from "../../../../Components/Headings/Headings";
import LoadingBar from "../../../../Components/LoadingBar/LoadingBar";
import { TextSection } from "../../../../Components/Text/Text";
import { useConditionalAccessResults } from "../../../../hooks/queries.hooks";

export default function ConditionalAccessResults() {
  const tenantId = useParams().tenantId as string;
  const { data, isError, error, isLoading } =
    useConditionalAccessResults(tenantId);
  return (
    <>
      <H3>Conditional Access Policies</H3>
      {isLoading && <LoadingBar />}
      {isError && <Text color="red">{error.message}</Text>}
      {data &&
        data.map((policy) => (
          <BoxContainer key={policy.id}>
            <TextSection>
              <strong>{policy.displayName}</strong>
            </TextSection>
            <Flex marginTop={4} marginX={4}>
              {Object.entries(policy.results).map((result) => (
                <Box key={result[0]} marginRight={4}>
                  <Stat>
                    <StatLabel>{result[0]}</StatLabel>
                    <StatNumber>{result[1]}</StatNumber>
                  </Stat>
                </Box>
              ))}
            </Flex>
          </BoxContainer>
        ))}
    </>
  );
}
