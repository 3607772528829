import { H3 } from "../../../../../Components/Headings/Headings";
import { TextSection } from "../../../../../Components/Text/Text";

export default function MobileAndPersonalDevicePolicy() {
  return (
    <>
      <H3 id="mobile-and-personal-device-policy">
        Mobile and Personal Device Policy
      </H3>
      <TextSection>
        Use of non-company owned wireless devices must be approved by management
        ahead of time before being connected to the company's wireless network.
        Devices logging into the Atomus Secure Environment must be pre-approved
        by company management prior to access. As part of the company's policy
        on the use of wireless technologies, wireless devices may only be used
        for work related purposes. Access to the wireless network is restricted
        to these established guidelines and is monitored and controlled
        accordingly.
      </TextSection>
      <TextSection>
        Use of mobile devices is not allowed unless for work related purposes or
        at the discretion of management. Mobile device connection to the
        company's system must be authorized ahead of time by company management.
        Work containing CUI should not be done on personally owned or systems
        external to the company. Voice over internet protocol is controlled
        within the Atomus Secure Environment and monitored by the company.
      </TextSection>
    </>
  );
}
