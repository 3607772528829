import { Table, Tag, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { H2 } from "../../../../Components/Headings/Headings";
import LoadingBar from "../../../../Components/LoadingBar/LoadingBar";
import { TextSection } from "../../../../Components/Text/Text";
import { useEmployeeHandbookQuery } from "../../../../hooks/queries.hooks";
import PolicyDefinitions from "./PolicyDefinitions";
import PolicyLinks from "./PolicyLinks";

export default function EmployeeHandbookSection() {
  const tenantId = useParams().tenantId as string;
  const { isLoading, data, isError, error } =
    useEmployeeHandbookQuery(tenantId);
  return (
    <>
      <H2 id="employeehandbook">Employee Handbook</H2>
      {isLoading && <LoadingBar />}
      {isError && (
        <Text color="red">
          Error getting user employee handbook data: {error.message}
        </Text>
      )}
      {data && (
        <Table>
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>Email</Th>
              <Th>Handbook Signed</Th>
              <Th>Handbook Version</Th>
            </Tr>
          </Thead>
          <Tbody>
            {data.map((user) => (
              <Tr key={user.email}>
                <Td>
                  {user.firstName} {user.lastName}
                </Td>
                <Td>{user.email}</Td>
                <Td>
                  <Tag colorScheme={user.handbookSigned ? "green" : "red"}>
                    {user.handbookSigned === null
                      ? "error"
                      : `${user.handbookSigned}`}
                  </Tag>
                </Td>
                <Td>{user.handbookSigned ? user.handbookVersion : "N/A"}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      )}
      <TextSection>
        This handbook outlines the key company policies defining the usage of
        CUI in the company
      </TextSection>
      <PolicyLinks />
      <PolicyDefinitions />
    </>
  );
}
