import { Table, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { H2, H3 } from "../../../Components/Headings/Headings";
import SectionLink from "../../../Components/Links/SectionLink";
import LoadingBar from "../../../Components/LoadingBar/LoadingBar";
import { TextSection } from "../../../Components/Text/Text";
import {
  useDbUsersQuery,
  useUsersAndRolesQuery,
} from "../../../hooks/queries.hooks";
import { useEffect, useState } from "react";

export default function UsersAndTheirRolesSection() {
  const tenantId = useParams().tenantId as string;
  const { isLoading, data, isError, error } = useUsersAndRolesQuery(tenantId);
  const {
    isLoading: dbLoading,
    data: dbData,
    isError: dbIsError,
    error: dbError,
  } = useDbUsersQuery(tenantId);
  const [activeUsernames, setActiveUsernames] = useState<
    (string | undefined)[]
  >([]);

  useEffect(() => {
    const usernames: string[] = [];
    if (dbData) {
      for (const user of dbData) {
        if (user.active) {
          usernames.push(user.username);
        }
      }
    }
    setActiveUsernames(usernames);
  }, [dbData]);

  return (
    <>
      <H2 id="usersandtheirroles">Users and Their Roles</H2>
      <TextSection>
        The list of users in <SectionLink sectionId="companyinfo" />
      </TextSection>
      <TextSection>
        The list of privileged users can be see here:{" "}
        <SectionLink sectionId="privilegedusersroles" />
      </TextSection>
      <H3>List of Users and Their Assigned Roles</H3>
      {(isLoading || dbLoading) && <LoadingBar />}
      {(isError || dbIsError) && (
        <Text color="red">
          Error getting users and their roles:{" "}
          {error?.message || dbError?.message}
        </Text>
      )}
      {data && dbData && (
        <Table variant="striped">
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>Email</Th>
              <Th>AAD Roles</Th>
              <Th>ARM Roles</Th>
            </Tr>
          </Thead>
          <Tbody>
            {data.map(
              (user) =>
                activeUsernames?.includes(
                  user.user.userPrincipalName.split("@").at(0)
                ) && (
                  <Tr key={user.user.userPrincipalName}>
                    <Td>{user.user.displayName}</Td>
                    <Td>{user.user.userPrincipalName}</Td>
                    <Td>
                      {JSON.stringify(
                        user.aadRoles.map(
                          (role) => role.roleDefinition.displayName
                        ),
                        null,
                        4
                      )}
                    </Td>
                    <Td>
                      {JSON.stringify(
                        user.armRoles.map(
                          (role) => role.roleDefinition.roleName
                        ),
                        null,
                        4
                      )}
                    </Td>
                  </Tr>
                )
            )}
          </Tbody>
        </Table>
      )}
    </>
  );
}
