import { Code, Text } from "@chakra-ui/react";
import { IReactChildren, IReactChildrenAndId } from "../../types/props.types";

export function TextSection({ children, id }: IReactChildrenAndId) {
  return (
    <>
      <Text id={id} color="gray.600" marginY={2}>
        {children}
      </Text>
    </>
  );
}

export function ItalicsTextSection({ children, id }: IReactChildrenAndId) {
  return (
    <>
      <Text id={id} color="gray.600" marginY={2} as="i">
        {children}
      </Text>
    </>
  );
}

export function BoldTextSection({ children, id }: IReactChildrenAndId) {
  return (
    <TextSection id={id}>
      <strong>{children}</strong>
    </TextSection>
  );
}

export function NotionCode({ children }: IReactChildren) {
  return <Code color="red">{children}</Code>;
}

export function ImageSubText({ children }: IReactChildren) {
  return (
    <Text fontSize="xs" color="gray.400">
      {children}
    </Text>
  );
}

export function JsonText({ children }: IReactChildren) {
  return <pre style={{ whiteSpace: "pre-wrap" }}>{children}</pre>;
}
