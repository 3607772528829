import {
  Box,
  Button,
  Center,
  Progress,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from "@chakra-ui/react";
import { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Column } from "react-table";
import PageContainer from "../../Components/PageContainer/PageContainer";
import SortableTable from "../../Components/SortableTable/SortableTable";
import tenantContext from "../../contexts/tenant.context";
import { useTenantsQuery } from "../../hooks/queries.hooks";
import { TenantData } from "../../types/atomus-internal-apis.types";
import TenantAuthLink from "./TenantAuthLink";
import { getAdminConsentLink } from "../../utils/utils";
import authContext from "../../contexts/auth.context";
import { useMsal } from "@azure/msal-react";

export default function TenantsListPage() {
  const navigate = useNavigate();
  const { setTenantInfo } = useContext(tenantContext);
  const { isGov } = useContext(authContext);
  const { instance } = useMsal();
  const [needsConsent, setNeedsConsent] = useState(false);
  const [authLinkDisabled, setAuthLinkDisabled] = useState(false);

  const { data, error, isLoading, isError, isRefetching, refetch } =
    useTenantsQuery();
  const columns = useMemo(
    (): Column<TenantData>[] => [
      {
        Header: "Company name",
        accessor: "companyName",
      },
      {
        Header: "Tenant domain",
        accessor: "domain",
      },
      {
        Header: "Tenant id",
        accessor: "tenantId",
      },
    ],
    []
  );

  useEffect(() => {
    if (error && error.message.includes("403")) {
      setNeedsConsent(true);
    }
  }, [error]);

  const handleOpenLink = () => {
    const userTenantId = instance.getActiveAccount()?.tenantId;
    if (userTenantId) {
      const consentUrl = getAdminConsentLink(userTenantId, isGov);
      window.open(consentUrl, "_blank");
    } else {
      setAuthLinkDisabled(true);
    }
  };

  const navToTenant = (tenantData: TenantData) => {
    setTenantInfo({
      companyName: tenantData.companyName,
      domain: tenantData.domain,
    });
    navigate(`/tenants/${tenantData.tenantId}`);
  };
  return (
    <PageContainer>
      {(isLoading || isRefetching) && (
        <Progress isIndeterminate borderRadius="md" />
      )}
      {isError && needsConsent ? (
        <VStack spacing={4} align="center">
          <Text>You must grant admin consent for use of this application.</Text>
          <Button
            colorScheme="blue"
            onClick={handleOpenLink}
            isDisabled={authLinkDisabled}
          >
            Open Admin Consent Link
          </Button>
          {authLinkDisabled && (
            <Text color="red">
              Unable to retrieve signed-in user's tenant id. Try signing out and
              signing back in.
            </Text>
          )}
        </VStack>
      ) : (
        <Center>{error?.message}</Center>
      )}
      {!isLoading && !isRefetching && data && (
        <>
          <SortableTable
            tableProps={{ columns, data: data.success }}
            onClick={navToTenant}
          />
          {data.authRequired.length > 0 && (
            <Box marginY={4}>
              <Text fontSize="xl" fontWeight="semibold" marginY={4}>
                Authentication required
              </Text>
              <Table marginY={4}>
                <Thead>
                  <Tr>
                    <Th>Company Name</Th>
                    <Th>Tenant ID</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {data.authRequired.map((tenant) => (
                    <TenantAuthLink
                      key={`AuthLink-${tenant.tenantId}` as string}
                      tenant={tenant}
                      refetchTenants={refetch}
                    />
                  ))}
                </Tbody>
              </Table>
            </Box>
          )}
          {data.error.length > 0 && (
            <Box marginY={4}>
              <Text fontSize="xl" fontWeight="semibold" marginY={4}>
                Tenant errors
              </Text>
              <Table marginY={4}>
                <Thead>
                  <Tr>
                    <Th>Tenant ID</Th>
                    <Th>Error message</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {data.error.map((tenant) => (
                    <Tr key={`Error-${tenant.tenantId}` as string}>
                      <Td>{tenant.tenantId}</Td>
                      <Td>{tenant.error}</Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </Box>
          )}
          <Center>
            <Button
              colorScheme="blue"
              variant="ghost"
              mt={8}
              onClick={() => refetch({ throwOnError: true })}
            >
              Refresh
            </Button>
          </Center>
        </>
      )}
    </PageContainer>
  );
}
