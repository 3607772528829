import { ListItem, UnorderedList } from "@chakra-ui/react";
import { H2 } from "../../../Components/Headings/Headings";
import SectionLink from "../../../Components/Links/SectionLink";

const REVIEW_EVENTS = [
  "Password changes",
  "Failed logons",
  "Failed accesses related to information systems",
  "Administrative privilege usage",
  "PIV credential usage",
  "Capability to log every file access both successful and unsuccessful",
  "Temporal Infrastructure and User Events",
  "User Behavior",
  "Network Traffic and Usage",
  "Cloud Storage Events",
  "Backup events",
  "Remote Access Sessions",
  "All the admin functions, changes made by the admins on the system",
  "All the activity done on accounts by the employees",
  "All the programs that exist as inventory",
  "All the machines with the hardware specs",
  "All the security controls on a particular device",
  "All the performance metrics from a machine",
  "All the sign-ins and type of sign-ins and where it is getting logged in",
  "All Office 365 activity with mails, files, and sharing",
  "All the data access logs",
  "All the configuration changes",
] as const;

export default function AuditableReviewEventsSection() {
  return (
    <>
      <H2 id="auditablereviewevents">Auditable Review Events</H2>
      <UnorderedList>
        {REVIEW_EVENTS.map((event, idx) => (
          <ListItem key={idx}>{event}</ListItem>
        ))}
        <ListItem>
          Auditing the <SectionLink sectionId="securitycontrols" />
        </ListItem>
      </UnorderedList>
    </>
  );
}
