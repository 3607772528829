import { useState } from "react";
import TenantContext, { ITenantInfo } from "../contexts/tenant.context";
import { IReactChildren } from "../types/props.types";

export default function TenantProvider({ children }: IReactChildren) {
  const [tenantInfo, setTenantInfo] = useState<ITenantInfo | undefined>(
    undefined
  );
  return (
    <TenantContext.Provider value={{ tenantInfo, setTenantInfo }}>
      {children}
    </TenantContext.Provider>
  );
}
