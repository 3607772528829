import { Box, Button, Flex, Heading, Text } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import PageContainer from "../../Components/PageContainer/PageContainer";
import ChildLink from "../../Components/Links/ChildLink";
import ParentLink from "../../Components/Links/ParentLink";
import { getDocumentationEvidenceZip } from "../../services/atomus-internal-apis.service";
import { useContext, useState } from "react";
import tenantContext from "../../contexts/tenant.context";
import { H3 } from "../../Components/Headings/Headings";
import { useMicrosoftToken } from "../../hooks/tokens.hooks";
import TenantLinkMenu from "./Sections/TenantLinksMenu";
import { CATEGORIES } from "../../categories";

export default function TenantHomePage() {
  const tenantId = useParams().tenantId as string;
  const tenantInfo = useContext(tenantContext).tenantInfo;
  const [isLoading, setIsLoading] = useState(false);
  const { getInternalApiToken } = useMicrosoftToken();

  const handleDownloadConfig = async (fileName: string) => {
    if (!isLoading) {
      setIsLoading(true);
      try {
        const token = await getInternalApiToken();
        const data = await getDocumentationEvidenceZip(token, tenantId);
        const elem = document.createElement("a");
        elem.setAttribute("href", `data:text/plain;base64,${data}`);
        elem.download = fileName;
        document.body.appendChild(elem);
        elem.click();
      } catch (error) {
        alert(error);
      }
      setIsLoading(false);
    }
  };

  return (
    <PageContainer>
      <Flex>
        <Text fontSize="2xl" fontWeight="semibold">
          {tenantInfo?.companyName || "ERROR: unknown company name"}
        </Text>
        <TenantLinkMenu tenantId={tenantId} />
      </Flex>
      <Box margin={2}>
        <ParentLink to="companyinfo" text="[0] Company Info" />
        {CATEGORIES.map((category) => (
          <Box key={category.categoryId}>
            <Heading size="md" fontWeight="semibold">
              {category.categoryName}
            </Heading>
            {category.sections.map((section) => (
              <ChildLink
                to={`${category.categoryId}/${section.sectionId}`}
                text={section.sectionName}
                key={`${category.categoryId}/${section.sectionId}`}
              />
            ))}
            <br />
          </Box>
        ))}
      </Box>
      <Box>
        <H3>Evidence Downloads</H3>
        <Button
          onClick={() => handleDownloadConfig("evidence.zip")}
          variant="link"
          isLoading={isLoading}
          isDisabled={isLoading}
        >
          Training and Employee Handbook PDFs
        </Button>
      </Box>
    </PageContainer>
  );
}
