import { Text } from "@chakra-ui/react";
import BoxContainer from "../../../../Components/Boxes/BoxContainer";
import { H3 } from "../../../../Components/Headings/Headings";
import LoadingBar from "../../../../Components/LoadingBar/LoadingBar";
import { useParams } from "react-router-dom";
import { useVpnQuery } from "../../../../hooks/queries.hooks";

export default function AccessServer() {
  const tenantId = useParams().tenantId as string;
  const { data, isLoading, isError, error } = useVpnQuery(tenantId);
  return (
    <>
      <H3>VPN Access Server Logs</H3>
      {isLoading && <LoadingBar />}
      {isError && (
        <Text color="red">Error getting Aegis users: {error.message}</Text>
      )}
      {data &&
        data.map((log, idx) => (
          <BoxContainer key={idx}>
            <pre style={{ whiteSpace: "pre-wrap" }}>
              {JSON.stringify(log, null, 4)}
            </pre>
          </BoxContainer>
        ))}
    </>
  );
}
