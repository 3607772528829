import { Text } from "@chakra-ui/react";
import BoxContainer from "../../../../Components/Boxes/BoxContainer";
import { H3 } from "../../../../Components/Headings/Headings";
import LoadingBar from "../../../../Components/LoadingBar/LoadingBar";
import { useParams } from "react-router-dom";
import { useVmConnectionQuery } from "../../../../hooks/queries.hooks";
import LinkCard from "../../../../Components/Links/LinkCard";
import SectionLink from "../../../../Components/Links/SectionLink";
import { TextSection } from "../../../../Components/Text/Text";

export default function VmConnection() {
  const tenantId = useParams().tenantId as string;
  const { data, isLoading, isError, error } = useVmConnectionQuery(tenantId);
  return (
    <>
      <H3>Network Audit and Logging on Aegis Devices</H3>
      <TextSection>
        The document below shows how a user in{" "}
        <SectionLink sectionId="companyinfo" /> can manage the VPN:
      </TextSection>
      <LinkCard
        link="https://docs.atomuscyber.com/article/28-where-can-i-manage-the-vpn"
        title="Where can I manage the VPN?"
      />
      <TextSection>
        All Network Connections are logged in a centralized log repository:
      </TextSection>
      {isLoading && <LoadingBar />}
      {isError && (
        <Text color="red">Error getting Aegis users: {error.message}</Text>
      )}
      {data &&
        data.map((log, idx) => (
          <BoxContainer key={idx}>
            <pre style={{ whiteSpace: "pre-wrap" }}>
              {JSON.stringify(log, null, 4)}
            </pre>
          </BoxContainer>
        ))}
    </>
  );
}
