import { H3 } from "../../../Components/Headings/Headings";
import { TextSection } from "../../../Components/Text/Text";
import CuiScreenshot1 from "../../../assets/screenshots/CuiLabelsScreenshot1.png";
import CuiScreenshot2 from "../../../assets/screenshots/CuiLabelsScreenshot2.png";
import InformationProtectionScreenshot1 from "../../../assets/screenshots/InformationProtectionScreenshot1.png";
import InformationProtectionScreenshot2 from "../../../assets/screenshots/InformationProtectionScreenshot2.png";
import SectionImage from "../../../Components/SectionImage/SectionImage";
import SectionLink from "../../../Components/Links/SectionLink";

export default function CuiEncryptionLabelsSection() {
  return (
    <>
      <H3 id="cuiencryptionlabels">CUI Encryption Labels</H3>
      <TextSection>
        Every time a user creates a file, it automatically gets assigned a
        label.
      </TextSection>
      <TextSection>
        Every time a user sends an email out, it automatically gets assigned a
        label, along with the attachment on that email.
      </TextSection>
      <TextSection>
        By default, companies can enable access control and encryption on those
        files within as well as outside their Aegis System.
      </TextSection>
      <TextSection>
        Whenever you send email outside the organization, it automatically
        encrypts the email and the attachment
      </TextSection>
      <SectionImage src={CuiScreenshot1} />
      <SectionImage src={CuiScreenshot2} />
      <TextSection>
        <SectionLink sectionId="companyinfo" /> uses the encryption labelling
        technology by Microsoft called Information Protection using sensitivity
        labels - Rights Management Service.
      </TextSection>
      <SectionImage src={InformationProtectionScreenshot1} />
      <SectionImage src={InformationProtectionScreenshot2} />
    </>
  );
}
