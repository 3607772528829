import { CategorySection } from "../../types/documentation.types";
import { Link } from "@chakra-ui/react";
import { HashLink } from "react-router-hash-link";
import { useParams } from "react-router-dom";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import { useCompanyInfoQuery } from "../../hooks/queries.hooks";
import { CATEGORIES } from "../../categories";

interface ISectionLinkInfo {
  categoryId: string;
  section: CategorySection;
}

const SECTIONS = CATEGORIES.reduce(
  (prev: Record<string, ISectionLinkInfo>, category) => {
    category.sections.forEach(
      (section) =>
        (prev[section.sectionId] = {
          categoryId: category.categoryId,
          section: section,
        })
    );
    return prev;
  },
  {}
);

interface ISectionLinkProps {
  sectionId: string;
}

export default function SectionLink({ sectionId }: ISectionLinkProps) {
  const params = useParams();
  const tenantId = params.tenantId as string;
  const { data, isLoading, isError, error } = useCompanyInfoQuery(tenantId);
  if (!tenantId) {
    return <Link color="red">ERROR: could not get tenant id from params</Link>;
  }

  if (sectionId === "companyinfo") {
    if (data) {
      return (
        <Link
          as={HashLink}
          to={`/tenants/${tenantId}/companyinfo`}
          fontWeight="semibold"
        >
          {data.companyName}
          <ExternalLinkIcon marginX={1} />
        </Link>
      );
    } else if (isLoading) {
      return <Link>Loading...</Link>;
    } else if (isError) {
      return (
        <Link color="red">Error getting company name: {error.message}</Link>
      );
    }
  }

  let section = SECTIONS[sectionId];
  if (!section) {
    return <Link color="red">ERROR: Section "{sectionId}" not found</Link>;
  }
  return (
    <Link
      as={HashLink}
      to={`/tenants/${tenantId}/${section.categoryId}/${section.section.sectionId}`}
      fontWeight="semibold"
    >
      {section.section.sectionName}
      <ExternalLinkIcon marginX={1} />
    </Link>
  );
}
