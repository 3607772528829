import { H3 } from "../../../../../Components/Headings/Headings";
import {
  ItalicsTextSection,
  TextSection,
} from "../../../../../Components/Text/Text";

export default function ForeignTravelPolicy() {
  return (
    <>
      <H3 id="foreign-travel-policy">Foreign Travel Policy</H3>
      <ItalicsTextSection>
        Note: This is a NIST 800-171 policy addendum to your company's current
        foreign travel policy
      </ItalicsTextSection>
      <TextSection>
        These guidelines emphasize the importance of implementing proactive
        security measures while traveling to ensure the protection of sensitive
        data and compliance. Before departing, ensure optimal security by
        leaving any unnecessary devices or media behind, backing up critical
        data using the Aegis application, scanning for malware, and verifying
        device encryption by ensuring that your compliance status displays as
        complete on the Aegis application. Notify Company Management and Atomus
        about your travel plans to prevent potential login issues from foreign
        locations. If work is necessary during travel, request a sanitized
        loaner device to avoid potential export control complications. Be aware
        of export controls when traveling abroad, particularly concerning
        devices, data, services, and interactions with specific individuals.
        Compliance with regulations such as ITAR, EAR, and OFAC is crucial to
        avoid legal issues.
      </TextSection>
      <TextSection>
        During travel, prioritize device security by keeping them physically
        close and secured. Use secure internet connections (cellular network,
        Aegis VPN) and avoid public Wi-Fi. Assume network insecurity and avoid
        using non-sanctioned devices for company-related activities. Never use
        shared or public computers for sensitive data, and exercise caution when
        accepting external media and public charging sources to prevent
        potential data breaches. In the event of a security incident, promptly
        report any lost, stolen, or compromised devices. Notify company
        administrators and Atomus immediately, and involve local authorities if
        necessary, to address breaches or data compromises effectively.
      </TextSection>
    </>
  );
}
