import { useMsal } from "@azure/msal-react";
import { useNavigate } from "react-router-dom";
import { MsalEnvironments, TokenScopes } from "../constants/auth.constants";

interface TokenScopesSpec {
  COMMERCIAL: readonly string[];
  GOV: readonly string[];
}

/**
 * @description functions to get a microsoft token
 */
export function useMicrosoftToken() {
  const { instance } = useMsal();
  const navigate = useNavigate();

  const getMicrosoftToken = async (
    scopes: Readonly<TokenScopesSpec>
  ): Promise<string> => {
    if (sessionStorage.getItem("current-internals-token")) {
      return `Bearer ${sessionStorage.getItem("current-internals-token")}`;
    }
    const account = instance.getActiveAccount();
    if (!account) {
      alert("Could not get active account");
      navigate("/login");
      return "";
    } else {
      let tokenScopes: string[];
      switch (account.environment) {
        case MsalEnvironments.COMMERCIAL:
          tokenScopes = [...scopes.COMMERCIAL];
          break;
        case MsalEnvironments.GOV:
          tokenScopes = [...scopes.GOV];
          break;
        default:
          throw new Error(
            `Unsupported account environment: ${account.environment}`
          );
      }
      let token: string;
      try {
        await instance.initialize();
        await instance.handleRedirectPromise();
        token = (
          await instance.acquireTokenSilent({
            scopes: tokenScopes,
          })
        ).accessToken;
      } catch (error) {
        token = (await instance.acquireTokenPopup({ scopes: tokenScopes }))
          .accessToken;
      }
      return `Bearer ${token}`;
    }
  };

  return {
    /**
     * @description get a token authenticated for the Atomus internal API scopes
     */
    getInternalApiToken: () => getMicrosoftToken(TokenScopes.InternalApi),
    /**
     * @description get a token authenticated for the Azure Resource Manager (arm)
     */
    getArmToken: () => getMicrosoftToken(TokenScopes.Arm),
  };
}
