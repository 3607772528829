import { Text } from "@chakra-ui/react";
import LoadingBar from "../LoadingBar/LoadingBar";

interface IQuerySectionProps {
  isError: boolean;
  error: Error | null;
  isLoading: boolean;
}

export default function QueryBoilerplate({
  isError,
  error,
  isLoading,
}: IQuerySectionProps) {
  if (isLoading) {
    return <LoadingBar />;
  }
  if (isError) {
    return (
      <Text color="red">
        {(error as Error).message || "an unknown error occurred"}
      </Text>
    );
  }
  return <></>;
}
