import { H2 } from "../../../../Components/Headings/Headings";
import SectionLink from "../../../../Components/Links/SectionLink";
import { TextSection } from "../../../../Components/Text/Text";
import ConditionalAccessResults from "./ConditionalAccessResults";

export default function AuthorizedUsersSection() {
  return (
    <>
      <H2 id="authorizedusers">Authorized Users</H2>
      <TextSection>
        This page identifies the list of Authorized Users for{" "}
        <strong>Atomus Aegis</strong>.
      </TextSection>
      <TextSection>
        <strong>Security Admin</strong> and <strong>Company Admin</strong> are{" "}
        <strong>Privileged Users</strong>.
      </TextSection>
      <TextSection>
        <SectionLink sectionId="privilegedusersroles" />
      </TextSection>
      <TextSection>
        <SectionLink sectionId="usersandtheirroles" />
      </TextSection>
      <TextSection>
        <SectionLink sectionId="processesonbehalfofauthorizedusers" />
      </TextSection>
      <TextSection>
        <SectionLink sectionId="authorizeddevices" />
      </TextSection>
      <ConditionalAccessResults />
    </>
  );
}
