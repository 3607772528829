import { H2 } from "../../../../Components/Headings/Headings";
import ChangeTracking from "./ChangeTracking";
import UpdateManagement from "./UpdateManagement";

export default function ConfigurationManagerSection() {
  return (
    <>
      <H2 id="configmanager">Configuration Manager Dashboard</H2>
      <ChangeTracking />
      <UpdateManagement />
    </>
  );
}
