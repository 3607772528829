import { Table, Tag, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { H2, H3 } from "../../../../Components/Headings/Headings";
import SectionLink from "../../../../Components/Links/SectionLink";
import { TextSection } from "../../../../Components/Text/Text";
import MatrixRowsJson from "./MatrixRows.json";

interface IMatrixRow {
  nistControlNumber: string;
  category: 1 | 2 | 3;
  controlType: "Basic" | "Derived";
  controlFamily: string;
  controlText: string;
}

const CATEGORY_COLOR_SCHEMES = {
  1: "blue",
  2: "orange",
  3: "purple",
} as const;

const CONTROL_TYPE_COLOR_SCHEMES = {
  Basic: "green",
  Derived: "gray",
} as const;

export default function SharedResponsibilitySection() {
  const matrixRows = MatrixRowsJson as IMatrixRow[];
  return (
    <>
      <H2>Shared Responsibility Matrix</H2>
      <TextSection>
        <Tag colorScheme="blue">Category 1</Tag> - Atomus Implemented and
        Documented. Control Owner - <strong>Security Admin</strong>
      </TextSection>
      <TextSection>
        <Tag colorScheme="orange">Category 2</Tag> - Policy Values by{" "}
        <SectionLink sectionId="companyinfo" /> and implemented and documented
        by Atomus. Control Owners - <strong>Security Admin</strong> and{" "}
        <strong>Company Admin</strong>
      </TextSection>
      <TextSection>
        <Tag colorScheme="purple">Category 3</Tag> -{" "}
        <SectionLink sectionId="companyinfo" /> Control Owner -{" "}
        <strong>Company Admin</strong>
      </TextSection>
      <H3>Responsibility Matrix</H3>
      <Table>
        <Thead>
          <Tr>
            <Th>NIST 800-171 Control Number</Th>
            <Th>Category</Th>
            <Th>Control Type</Th>
            <Th>Control Family</Th>
            <Th>Control Text</Th>
          </Tr>
        </Thead>
        <Tbody>
          {matrixRows.map((row) => (
            <Tr key={row.nistControlNumber}>
              <Td>{row.nistControlNumber}</Td>
              <Td>
                <Tag colorScheme={CATEGORY_COLOR_SCHEMES[row.category]}>
                  {row.category}
                </Tag>
              </Td>
              <Td>
                <Tag colorScheme={CONTROL_TYPE_COLOR_SCHEMES[row.controlType]}>
                  {row.controlType}
                </Tag>
              </Td>
              <Td>{row.controlFamily}</Td>
              <Td>{row.controlText}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </>
  );
}
