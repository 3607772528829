import { Table, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import BoxContainer from "../../../Components/Boxes/BoxContainer";
import { H2 } from "../../../Components/Headings/Headings";
import SectionLink from "../../../Components/Links/SectionLink";
import LoadingBar from "../../../Components/LoadingBar/LoadingBar";
import { TextSection } from "../../../Components/Text/Text";
import { useDeviceProcessesQuery } from "../../../hooks/queries.hooks";

export default function ProcessesOnBehalfOfAuthorizedUserSection() {
  const tenantId = useParams().tenantId as string;
  const { data, isLoading, isError, error } = useDeviceProcessesQuery(tenantId);
  return (
    <>
      <H2 id="processesonbehalfofauthorizedusers">
        Processes on Behalf of Users
      </H2>
      {isLoading && <LoadingBar />}
      {isError && (
        <Text color="red">Error getting device processes: {error.message}</Text>
      )}
      <TextSection>
        Processes running on behalf of{" "}
        <SectionLink sectionId="authorizedusers" /> on{" "}
        <SectionLink sectionId="authorizeddevices" />:
      </TextSection>
      {data &&
        Object.entries(data).map(([deviceName, processes]) => (
          <BoxContainer key={deviceName}>
            <TextSection>
              <strong>{deviceName}</strong>
            </TextSection>
            {processes === null ? (
              <TextSection>No processes found</TextSection>
            ) : (
              <Table>
                <Thead>
                  <Tr>
                    <Th>User Name</Th>
                    <Th>Process Name</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {processes?.map((process) => (
                    <Tr>
                      <Td>{process.UserName}</Td>
                      <Td>{process.DisplayName}</Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            )}
          </BoxContainer>
        ))}
    </>
  );
}
