import { ListItem, UnorderedList } from "@chakra-ui/react";
import { H2, H3 } from "../../../Components/Headings/Headings";
import SectionLink from "../../../Components/Links/SectionLink";
import { TextSection } from "../../../Components/Text/Text";

export default function WiFiStandardSection() {
  return (
    <>
      <H2 id="wifi">Wi-Fi Standard</H2>
      <TextSection>
        There are two Wi-Fis available at on-premise in{" "}
        <SectionLink sectionId="companyinfo" />.
      </TextSection>
      <H3>Guest Wi-Fi</H3>
      <UnorderedList>
        <ListItem>
          <TextSection>
            Passwords for guest Wi-Fi is shared with logged visitors
          </TextSection>
        </ListItem>
      </UnorderedList>
      <H3>Company Wi-Fi</H3>
      <UnorderedList>
        <ListItem>
          <TextSection>
            It has no communication with the Guest Wi-Fi and is only allowed for{" "}
            <SectionLink sectionId="authorizedusers" /> to access
          </TextSection>
        </ListItem>
        <ListItem>
          <TextSection>
            Wi-Fi which is <strong>802.11i/WPA2/WPA3</strong>, makes use of
            AES-CCMP and AES-GCMP for data encryption and a key derivation
            function based on a SHA2 family hash algorithm, all of which are
            compliant with <strong>FIPS 140-3</strong>
          </TextSection>
        </ListItem>
      </UnorderedList>
    </>
  );
}
