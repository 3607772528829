import { Link } from "@chakra-ui/react";
import { HashLink } from "react-router-hash-link";

interface IParentLinkParams {
  to: string;
  text: string;
}

export default function ChildLink({ to, text }: IParentLinkParams) {
  return (
    <>
      <Link as={HashLink} to={to} fontSize="lg" color="blue.400" marginLeft={4}>
        {text}
      </Link>
      <br />
    </>
  );
}
