import { Link } from "@chakra-ui/react";
import { H3 } from "../../../Components/Headings/Headings";
import SectionLink from "../../../Components/Links/SectionLink";
import { TextSection } from "../../../Components/Text/Text";
import { CompanyInformation } from "../../../types/atomus-internal-apis.types";

interface IGeneralInfoSectionProps {
  companyInfo: CompanyInformation;
}

export default function GeneralInfoSection({
  companyInfo,
}: IGeneralInfoSectionProps) {
  return (
    <>
      {companyInfo.companyWebsite && (
        <>
          <H3>Website</H3>
          <Link href={companyInfo.companyWebsite} isExternal>
            {companyInfo.companyWebsite}
          </Link>
        </>
      )}
      {companyInfo.companyAboutUsWebsite && (
        <>
          <H3>About Us</H3>
          <Link href={companyInfo.companyAboutUsWebsite} isExternal>
            {companyInfo.companyAboutUsWebsite}
          </Link>
        </>
      )}
      {(companyInfo.email ||
        companyInfo.phone ||
        companyInfo.companyContactUsWebsite) && (
        <>
          <H3>Contact Details</H3>
          {companyInfo.name && (
            <TextSection>
              <strong>Name:</strong> {companyInfo.name}
            </TextSection>
          )}
          {companyInfo.email && (
            <TextSection>
              <strong>Email:</strong> {companyInfo.email}
            </TextSection>
          )}
          {companyInfo.phone && (
            <TextSection>
              <strong>Phone:</strong> {companyInfo.phone}
            </TextSection>
          )}
          {companyInfo.companyContactUsWebsite && (
            <TextSection>
              <strong>Website: </strong>
              <Link href={companyInfo.companyContactUsWebsite} isExternal>
                {companyInfo.companyContactUsWebsite}
              </Link>
            </TextSection>
          )}
        </>
      )}
      <H3>Privileged Users</H3>
      <SectionLink sectionId="privilegedusers" />
    </>
  );
}
