import axios, { AxiosResponse, AxiosError } from "axios";
import {
  AzureUserInfo,
  AppInfoResponse,
  CompanyInformation,
  ConditionalAccessPolicyResults,
  Device,
  DeviceApplication,
  LegacyDeviceHealthcheck,
  DeviceProcess,
  DeviceVulnerability,
  DnsResult,
  ErrorAegisResponse,
  HealthcheckRow,
  Platform,
  RegKeyConfiguration,
  SuccessAegisResponse,
  TenantListResponse,
  TenantLogType,
  UserAndRoles,
  UserEmployeeHandbookStatus,
  UserTrainingStatus,
  VpnSslResponse,
  CompanyAoStatusItem,
  AssessmentObjective,
  AegisUser,
} from "../types/atomus-internal-apis.types";
import { POAMSpecificItem } from "../types/documentation.types";

const BASE_URL =
  process.env.REACT_APP_INTERNAL_BASE_URL || "http://localhost:5005";
const instance = axios.create({
  baseURL: BASE_URL,
});

/* =====================================================================================
 * These routes were specifically created for this project and will definitely be used *
 * =====================================================================================
 */

/**
 * @description get a list of all users and their assigned roles
 * @param token authentication token
 * @param tenantId id of the tenant
 * @returns a list of all users in the tenant and their assigned roles
 */
export async function listUsersAndRoles(
  token: string,
  tenantId: string
): Promise<UserAndRoles[]> {
  const path = `/tenants/${tenantId}/documentation/users`;
  return sendGet(path, token);
}

/**
 * @description get a company's info such as contact info, website, etc.
 * @param token authentication token
 * @param tenantId id of the tenant
 * @returns the company info
 */
export async function getCompanyInfo(
  token: string,
  tenantId: string
): Promise<CompanyInformation> {
  const path = `/tenants/${tenantId}/info`;
  return sendGet(path, token);
}

/* ================================================
 * These are existing routes that might be useful *
 * ================================================
 */
/**
 * @description list all tenants the token has access to
 * @param token authentication token
 * @returns response containing the tenants
 */
export async function listTenants(token: string): Promise<TenantListResponse> {
  const path = "/tenants";
  return sendGet(path, token);
}

/**
 * @descirption get a list of registered devices in the tenant
 * @param token authentication token
 * @param tenantId id of the tenant
 * @returns the list of devices
 */
export async function listDevices(
  token: string,
  tenantId: string
): Promise<Device[]> {
  const path = `/tenants/${tenantId}/devices`;
  return sendGet(path, token);
}

/**
 * @descirption get a list of devices associated with tenant id from the database
 * @param token authentication token
 * @param tenantId id of the tenant
 * @returns the list of devices
 */
export async function listDbDevices(
  token: string,
  tenantId: string
): Promise<Device[]> {
  const path = `/tenants/${tenantId}/devices/db`;
  return sendGet(path, token);
}

/**
 * @description get a list of applications installed on a device
 * @param token authentication token
 * @param tenantId id of the tenant
 * @param deviceId id of the device
 * @param deviceName display name of the device
 * @returns list of all installed applications on a device
 */
export async function getDeviceApplications(
  token: string,
  tenantId: string,
  deviceId: string,
  deviceName: string
): Promise<DeviceApplication[]> {
  const params = new URLSearchParams({ deviceName });
  const path = `/tenants/${tenantId}/devices/${deviceId}/applications?${params.toString()}`;
  return sendGet(path, token);
}

/**
 * @description get a list of processes and the user they belong to on a device
 * @param token authentication token
 * @param tenantId id of the tenant
 * @param deviceId id of the device
 * @param deviceName display name of the device
 * @returns list of processes from the last 7 days
 */
export async function getDeviceProcesses(
  token: string,
  tenantId: string,
  deviceId: string,
  deviceName: string
): Promise<DeviceProcess[]> {
  const params = new URLSearchParams({ deviceName });
  const path = `/tenants/${tenantId}/devices/${deviceId}/processes?${params.toString()}`;
  return sendGet(path, token);
}

/**
 * @description get the current version of Aegis in a specified tenant
 * @param token authentication token
 * @param tenantId id of the tenant
 * @param platform platform to get the app info for
 * @returns the app info for the specified platform(s)
 */
export async function getTenantAppInfo(
  token: string,
  tenantId: string,
  platform: Platform
): Promise<AppInfoResponse> {
  return sendGet(`/tenants/${tenantId}/app-info?platform=${platform}`, token);
}

/**
 * @description get the dns record status for the tenant's default domain
 * @param token authentication token
 * @param tenantId id of the tenant
 * @returns response containing the dns status for specified records
 */
export async function getTenantDnsStatus(
  token: string,
  tenantId: string
): Promise<DnsResult> {
  return sendGet(`/tenants/${tenantId}/dns`, token);
}

/**
 * @description get the ssl certificate status for a tenant's vpn access server
 * @param token authentication token
 * @param tenantId tenant id
 * @returns the ssl status for the vpn
 */
export async function getVpnSslStatus(
  token: string,
  tenantId: string
): Promise<VpnSslResponse> {
  return await sendGet(`/tenants/${tenantId}/state/vpn-ssl`, token);
}

/**
 * @description get the registry key configuration for a tenant
 * @param token the authentication to use for the request
 * @param tenantId id of the tenant to get the key config for. Passing `"global"`
 * for `tenantId` gets the universal registry key configuration
 * @returns
 */
export async function getRegistryKeys(
  token: string,
  tenantId: string
): Promise<RegKeyConfiguration> {
  const path = `/tenants/${tenantId}/keys?platform=win32`;
  return await sendGet(path, token);
}

/**
 * @description get the full registry key configuration for a tenant (global keys
 * and tenant specific keys)
 * @param token the authentication to use for the request
 * @param tenantId id of the tenant to get the key config for. Passing `"global"`
 * for `tenantId` gets the universal registry key configuration
 * @returns the list of global registry keys merged with the list of tenant registry keys
 */
export async function getAllRegistryKeys(
  token: string,
  tenantId: string
): Promise<RegKeyConfiguration> {
  const path = `/tenants/${tenantId}/keys/all?platform=win32`;
  return await sendGet(path, token);
}

/**
 * @description get a list of users in the tenant and their MFA status and
 * assigned license count
 * @param token authentication token
 * @param tenantId id of the tenant
 * @returns the list of Aegis users
 */
export async function getAzureUsers(
  token: string,
  tenantId: string
): Promise<AzureUserInfo[]> {
  const path = `/tenants/${tenantId}/users/msft`;
  return sendGet(path, token);
}

/**
 * @description get a list of db users in the tenant
 * @param tenantId id of the tenant
 * @returns the list of db users
 */
export async function getDbUsers(tenantId: string): Promise<AegisUser[]> {
  const path = `/tenants/${tenantId}/users`;
  const token = process.env.REACT_APP_INTERNAL_API_KEY;
  if (!token) {
    throw new Error("Unable to access api key");
  }
  return sendGet(path, token);
}

/**
 * @description get the training statuses for all user in the company
 * @param tenantId id of the tenant
 * @returns list of the company's users and whether or not their training is complete.
 * `null` in the `trainingComplete` field for a user indicates error
 */
export async function getUserTrainingStatuses(
  tenantId: string
): Promise<UserTrainingStatus[]> {
  const path = `/tenants/${tenantId}/documentation/training-statuses`;
  return sendGet(path, "");
}

/**
 * @description get the training statuses for all user in the company
 * @param tenantId id of the tenant
 * @returns list of the company's users and whether or not their training is complete.
 * `null` in the `trainingComplete` field for a user indicates error
 */
export async function getUserHandbookStatuses(
  tenantId: string
): Promise<UserEmployeeHandbookStatus[]> {
  const path = `/tenants/${tenantId}/documentation/handbook-statuses`;
  return sendGet(path, "");
}

/**
 * @description get the documentation evidence zip
 * @param token auth token
 * @param tenantId id of the tenant
 * @returns base64 encoded string of the zip data
 */
export function getDocumentationEvidenceZip(
  token: string,
  tenantId: string
): Promise<string> {
  const path = `/tenants/${tenantId}/documentation/evidence`;
  return sendGet(path, token);
}

/**
 * @description list all ssp controls
 * @param tenantId tenant id (only used for the route)
 * @returns a list of all ssp control rows in our database
 */
export function getSspControls(
  token: string,
  tenantId: string
): Promise<AssessmentObjective[]> {
  const path = `/tenants/${tenantId}/documentation/ssp/controls`;
  return sendGet(path, token); // no token required for ssp controls
}

/**
 * @description get all POA&M items for a company based on their ssp statuses in the db
 * @param token Microsoft issued auth token
 * @param tenantId the tenant id of the company for which to get the POA&M items
 * @returns a list of POA&M items
 */
export function getPoamItems(
  token: string,
  tenantId: string
): Promise<POAMSpecificItem[]> {
  const path = `/tenants/${tenantId}/documentation/ssp/poam`;
  return sendGet(path, token);
}

/**
 * @description get all implemented control status rows for a company
 * @param token Microsoft issued auth token
 * @param tenantId the tenant id of the company to for which get the statuses
 * @returns a list of ssp statuses
 */
export function getControlStatuses(
  token: string,
  tenantId: string
): Promise<CompanyAoStatusItem[]> {
  const path = `/tenants/${tenantId}/documentation/ssp/statuses`;
  return sendGet(path, token);
}

/**
 * @description get the company's ssp score
 * @param token Microsoft issued auth token
 * @param tenantId the tenant id of the company to for which get the score
 * @returns the ssp score as a number
 */
export function getSspScore(
  token: string,
  tenantId: string
): Promise<CompanyAoStatusItem[]> {
  const path = `/tenants/${tenantId}/documentation/ssp/score`;
  return sendGet(path, token);
}

/**
 * @description get a list of software vulnerabilities present in a specified tenant
 * @param token token to authorized the request
 * @param tenantId id of the tenant
 * @returns list of vulnerabilities in the tenant and the affected devices
 */
export function getDeviceVulnerabilities(
  token: string,
  tenantId: string
): Promise<DeviceVulnerability[]> {
  const path = `/tenants/${tenantId}/devices/vulnerabilities`;
  return sendGet(path, token);
}

/**
 * @description get a list of conditional access policy results in a tenant
 * @param token token to authorize the request
 * @param tenantId id of the tenant
 * @returns list of conditional access policies in the tenant and the result counts
 * over the last 30 days
 */
export function getConditionalAccessResults(
  token: string,
  tenantId: string
): Promise<ConditionalAccessPolicyResults[]> {
  const path = `/tenants/${tenantId}/conditional-access-results`;
  return sendGet(path, token);
}

/**
 * @description get the latest healthcheck for all windows Aegis devices in the tenant
 * @param token token to authorize the request
 * @param tenantId id of the tenant
 * @returns list of healthchecks for windows aegis devices
 */
export async function getLatestHealthchecks(
  token: string,
  tenantId: string
): Promise<(LegacyDeviceHealthcheck | HealthcheckRow)[]> {
  const path = `/tenants/${tenantId}/healthchecks`;
  return sendGet(path, token);
}

export async function getTenantLogs<T>(
  token: string,
  tenantId: string,
  logType: TenantLogType
): Promise<T> {
  const queryParams = new URLSearchParams({ logType });
  const path = `/tenants/${tenantId}/logs?${queryParams.toString()}`;
  return sendGet(path, token);
}

/* ========================= Helper/Utility functions ======================== */

async function sendGet<T>(path: string, token: string): Promise<T> {
  try {
    const res = await instance.get<SuccessAegisResponse<T>>(encodeURI(path), {
      headers: { authorization: token, source: "documentation" },
    });
    return validateResponse(res);
  } catch (error) {
    handleError(error);
  }
}

function validateResponse<T>(
  res: AxiosResponse<SuccessAegisResponse<T>, any>
): T {
  if (isSuccessAegisResponse(res.data)) {
    return res.data.data;
  } else if (isErrorAegisResponse(res.data)) {
    throw Error((res.data as ErrorAegisResponse).error);
  }
  throw Error(
    "Recieved invalid response from automate: success response is not of type SuccessAegisResponse"
  );
}

function handleError(error: any): never {
  if (axios.isAxiosError(error)) {
    const aegisError = (error as AxiosError<ErrorAegisResponse>).response?.data;
    if (isErrorAegisResponse(aegisError)) {
      throw new Error(aegisError.error);
    }
    throw new Error(error.message);
  }
  throw error;
}

export function isSuccessAegisResponse(
  data: any
): data is SuccessAegisResponse<any> {
  return data && data.isAegisResponse && data.success;
}

export function isErrorAegisResponse(data: any): data is ErrorAegisResponse {
  return data && data.isAegisResponse && data.success === false;
}
