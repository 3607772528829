import { ListItem, UnorderedList } from "@chakra-ui/react";
import { H2 } from "../../../Components/Headings/Headings";
import { TextSection } from "../../../Components/Text/Text";

export default function CompanySpecificPolicyValuesSection() {
  return (
    <>
      <H2 id="companyspecificpolicyvalues">Company Specific Policy Values</H2>
      <TextSection>Session inactivity limit - 15 minutes</TextSection>
      <TextSection>Account lockout threshold - 10 invalid logins</TextSection>
      <TextSection>Reuse of Identifiers - 2 years</TextSection>
      <TextSection>
        Period of inactivity for disabling the user - 30 days
      </TextSection>
      <TextSection>Password Complexity values</TextSection>

      <TextSection>MinimumPasswordAge in days: 0</TextSection>

      <TextSection>MaximumPasswordAge in days: 365</TextSection>

      <TextSection>MinimumPasswordLength: 12</TextSection>
      <TextSection>
        <UnorderedList marginLeft={6}>
          <ListItem>Password must be eight or more characters long.</ListItem>
          <ListItem>
            Password must contain characters from two of the following four
            categories:
            <UnorderedList>
              <ListItem>Uppercase characters A-Z (Latin alphabet)</ListItem>
              <ListItem>Lowercase characters a-z (Latin alphabet)</ListItem>
              <ListItem>Digits 0-9</ListItem>
              <ListItem>Special characters (!, $, #, %, etc.)</ListItem>
            </UnorderedList>
          </ListItem>
        </UnorderedList>
      </TextSection>
      <TextSection>
        PasswordHistorySize (number of passwords remembered so user doesnt set
        password to a previous password): 3
      </TextSection>
      <TextSection>Incident Response Time for Employees - 48 hours</TextSection>
      <TextSection>Frequency of Vulnerability Scans - 1 month</TextSection>
      <TextSection>Audit Logs record retention - 3 months</TextSection>
      <TextSection>Disable users inactivity time limit - 30 days</TextSection>
      <TextSection>Backup frequency - 1w</TextSection>
      <TextSection>Backup Test and Recovery Frequency - 1y</TextSection>
      <TextSection>Recovery time objectives (RTO) - 4 hrs</TextSection>
      <TextSection>Recovery point objectives (RPO) - 1w</TextSection>
      <TextSection>
        The length of time backups need to be retained - 3m
      </TextSection>
    </>
  );
}
