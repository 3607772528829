import { ListItem, UnorderedList } from "@chakra-ui/react";
import { H3 } from "../../../../../Components/Headings/Headings";
import SectionLink from "../../../../../Components/Links/SectionLink";
import { TextSection } from "../../../../../Components/Text/Text";

export default function PhysicalSecurityPolicies() {
  return (
    <>
      <H3 id="physical-security-policies">Physical Security Policies</H3>
      <UnorderedList>
        <ListItem>
          <TextSection>All the doors are secured.</TextSection>
        </ListItem>
        <ListItem>
          <TextSection>
            All the visitors are escorted, logged and monitored.
          </TextSection>
        </ListItem>
        {/* TODO: this feels like it needs updating */}
        <ListItem>
          <TextSection>
            The list of personnel with authorized access.
          </TextSection>
        </ListItem>
        <ListItem>
          <TextSection>
            The facility is monitored and for physical access and{" "}
            <strong>Company Admin</strong> as well as <strong>Employees</strong>{" "}
            have access to respond to physical security incidents.
          </TextSection>
        </ListItem>
        <ListItem>
          <TextSection>
            All the removable system media and system output is clearly marker
            as per the distribution limitations (Labelled with CUI, or have
            folders indicating the guidelines for sharing that information).
          </TextSection>
        </ListItem>
        <ListItem>
          <TextSection>
            All the security and privacy notices about CUI Handling Policy (from
            the <SectionLink sectionId="employeehandbook" /> and the{" "}
            <SectionLink sectionId="trainingslides" />) must be displayed in the
            premises of the Company.
          </TextSection>
        </ListItem>
        <ListItem>
          <TextSection>
            All the removable medias need to be encrypted when carried outside
            of the Campus.
          </TextSection>
        </ListItem>
        <ListItem>
          <TextSection>
            All the unused removable media is removed and disabled.
          </TextSection>
        </ListItem>
      </UnorderedList>
    </>
  );
}
