import { AzureCloudInstance, Configuration } from "@azure/msal-browser";

export const MSAL_REDIRECT_PATH = "/msal-redirect";

const MSAL_REDIRECT_URI = `${
  process.env.REACT_APP_VERCEL_BASE_URI || "http://localhost:3000"
}${MSAL_REDIRECT_PATH}`;

const CACHE_CONFIG = {
  cacheLocation: "sessionStorage", // This configures where your cache will be stored
  storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
} as const;

export const MsalAuthConfigs: Record<"commercial" | "gov", Configuration> = {
  commercial: {
    auth: {
      clientId: "9350821d-acc1-462f-b4b5-bc4f1acff6cf",
      authority: "https://login.microsoftonline.com/organizations",
      redirectUri: MSAL_REDIRECT_URI,
      azureCloudOptions: { azureCloudInstance: AzureCloudInstance.AzurePublic },
    },
    cache: CACHE_CONFIG,
  },
  gov: {
    auth: {
      clientId: "996b6d6d-61d6-4a87-af31-848fa3a6613a",
      authority: "https://login.microsoftonline.us/organizations",
      redirectUri: MSAL_REDIRECT_URI,
      azureCloudOptions: {
        azureCloudInstance: AzureCloudInstance.AzureUsGovernment,
      },
    },
    cache: CACHE_CONFIG,
  },
} as const;

export const MsalEnvironments = {
  COMMERCIAL: "login.windows.net",
  GOV: "login.microsoftonline.us",
} as const;

export const TokenScopes = {
  InternalApi: {
    COMMERCIAL: [
      "api://cd7af421-8824-4756-901b-422327d4dd95/user_impersonation",
    ],
    GOV: ["api://f9c7d374-fbee-478c-ba0b-d894520a5761/user_impersonation"],
  },
  Arm: {
    COMMERCIAL: ["https://management.azure.com/user_impersonation"],
    GOV: ["https://management.usgovcloudapi.net/user_impersonation"],
  },
} as const;

export const MsftLoginUrls = {
  COMMERCIAL: "https://login.microsoftonline.com",
  GOV: "https://login.microsoftonline.us",
};
