import { H2 } from "../../../Components/Headings/Headings";
import SectionLink from "../../../Components/Links/SectionLink";
import { TextSection } from "../../../Components/Text/Text";

export default function NonVendorSupportedProductsSection() {
  return (
    <>
      <H2 id="nonvendorsupportedproducts">Non-Vendor Supported Products</H2>
      <TextSection>
        <SectionLink sectionId="companyinfo" /> does not have any products that
        are not supported by vendors as per company policies.
      </TextSection>
      <TextSection>
        The <strong>Company Admin</strong> works with the employees of the
        company to find a path of transitioning of usage of a supported product
        and keeps the <strong>Security Admin</strong> updated for documentation
        purposes.
      </TextSection>
    </>
  );
}
