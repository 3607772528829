import { Td, Tr } from "@chakra-ui/react";
import authContext from "../../contexts/auth.context";
import { useContext } from "react";
import { getAdminConsentLink } from "../../utils/utils";
import { AuthRequiredTenant } from "../../types/atomus-internal-apis.types";

interface ITenantAuthLinkProps {
  tenant: AuthRequiredTenant;
  refetchTenants: () => void;
}

export default function TenantAuthLink({
  tenant,
  refetchTenants,
}: ITenantAuthLinkProps) {
  const { isGov } = useContext(authContext);
  const consentLink = tenant.tenantId
    ? getAdminConsentLink(tenant.tenantId, isGov)
    : "https://atomuscyber.us";
  const handleAuth = async () => {
    window.open(consentLink, "_blank");
    refetchTenants();
  };

  return (
    <Tr
      _hover={{ cursor: "pointer", background: "gray.200" }}
      onClick={handleAuth}
    >
      <Td>{tenant.companyName}</Td>
      <Td>{tenant.tenantId}</Td>
    </Tr>
  );
}
