import { Table, Tag, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { H2, H3 } from "../../../Components/Headings/Headings";
import LinkCard from "../../../Components/Links/LinkCard";
import QueryBoilerplate from "../../../Components/QueryBoilerplate/QueryBoilerplate";
import { TextSection } from "../../../Components/Text/Text";
import { useTrainingStatusQuery } from "../../../hooks/queries.hooks";

export default function TrainingEvidenceSection() {
  const tenantId = useParams().tenantId as string;
  const { isLoading, data, isError, error } = useTrainingStatusQuery(tenantId);
  return (
    <>
      <H2 id="trainingevidence">Training Evidence</H2>
      <TextSection>We use the following links for training:</TextSection>
      <LinkCard
        link="https://securityhub.usalearning.gov/"
        title="CUI Training Certificate Link"
      />
      <LinkCard
        link="https://securityawareness.usalearning.gov/itawareness/index.htm#"
        title="Insider Threat Awareness Certificate"
      />
      <LinkCard
        link="https://securityawareness.usalearning.gov/initialorientation/index.htm"
        title="DoD Cybersecurity Awareness Training Certificate"
      />
      <H3>User Training Statuses</H3>
      <QueryBoilerplate {...{ isLoading, isError, error }} />
      {data && (
        <Table>
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>Email</Th>
              <Th>Training Complete</Th>
            </Tr>
          </Thead>
          <Tbody>
            {data.map((user) => (
              <Tr key={user.email}>
                <Td>
                  {user.firstName} {user.lastName}
                </Td>
                <Td>{user.email}</Td>
                <Td>
                  <Tag colorScheme={user.trainingComplete ? "green" : "red"}>
                    {user.trainingComplete === null
                      ? "error"
                      : `${user.trainingComplete}`}
                  </Tag>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      )}
    </>
  );
}
