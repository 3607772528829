import { Box } from "@chakra-ui/react";
import { IReactChildren } from "../../types/props.types";
import Navbar from "../Navbar/Navbar";

export default function PageContainer({ children }: IReactChildren) {
  return (
    <Box>
      <Navbar />
      <Box mt={8} marginX={8} mb={16}>
        {children}
      </Box>
    </Box>
  );
}
